import { useState } from "react";
import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const PaidOrder = () => {
    const [products, setProducts] = useState([]);
    const [filter, setFilter] = useState("");
    const [paidPrice, setPaidPrice] = useState(0);
    const [refetchOrders, setRefetchOrders] = useState(false);
    const [delivery, setDelivery] = useState(0)
    useEffect(() => {
        const url = "https://api.rebzigo.com/orders"
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const paidOrder = data.filter(order => order.status === 'Shipping');
                setProducts(paidOrder)
            })
    }, [refetchOrders])
    const navigate = useNavigate()
    const dataSearch = products?.filter((item) => {
        return Object?.keys(item)?.some((key) => {
            const value = item[key];
            return (
                value !== null &&
                value !== undefined &&
                value
                    .toString()
                    .toLowerCase()
                    .includes(filter.toString().toLowerCase())
            );
        });
    });
    const reverse = [...dataSearch].reverse()
    const searchEvent = (event) => {
        setFilter(event.target.value);
    };
    const handleNavigateOrders = (id) => {
        navigate(`/dashboard/Orders/${id}`)
    }
    const handleStatusChange = (orderId, newStatus) => {
        fetch(`https://api.rebzigo.com/orders/${orderId}/updateStatus`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ newStatus }),
        })
            .then((res) => res.json())
            .then((data) => {
                const updatedOrders = products.map((order) => {
                    if (order._id === orderId) {
                        return { ...order, status: data.status };
                    }
                    return order;
                });
                setProducts(updatedOrders);
                setRefetchOrders(prevState => !prevState);
            })
    };

    const handleNewPriceChange = (e, id) => {
        const { value } = e.target;
        setPaidPrice(value)
    }
    const handlePriceUpdate = (orderId, price, lastPrice, currentStatus) => {
        const newPrice = paidPrice;
        const lastPrices = parseFloat(lastPrice ? lastPrice : 0)
        const prices = parseFloat(price) - lastPrices;
        if (parseFloat(newPrice) !== parseFloat(prices)) {
            toast?.error(`Your Price is out of range, add price ${prices}`)
        } else {
            const confirmed = window.confirm("Are you sure you want to Update Price?");
            if (confirmed) {
                fetch(`https://api.rebzigo.com/orders/${orderId}/paidPrice`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ newPrice }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.newPrice) {
                            toast.success("Price updated successfully");
                            // Update status if needed
                            if (currentStatus !== "Paid") {
                                handleStatusChange(orderId, "Paid");
                            }
                        } else {
                            toast.error("Failed to update price");
                        }
                    })
                    .catch((error) => {
                        toast.error("Failed to update price");
                    });
            }
        }
    };

    const totalSellPrice = reverse?.reduce((total, order) => total + (order.discountedTotal || 0), 0)
    const totalBenifitPriceData = reverse?.reduce((total, order) => total + (order.purchaseTotal || 0), 0);
    const totalBenifits = parseFloat(totalSellPrice) - parseFloat(totalBenifitPriceData)
    const handleDeliveryChange = (e) => {
        const { value } = e.target;
        setDelivery(value)
    }
    const handleDeliveryClick = (orderId) => {

        const deliveryId = delivery
        fetch(`https://api.rebzigo.com/orders/${orderId}/delivery`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ deliveryId }),
        })
            .then((res) => res.json())
            .then((data) => {
                toast.success('Delivery Id Successfully Added')
                setRefetchOrders(prevState => !prevState);
            })
    }
    return (
        <section className="my-4">
            <div className="flex justify-around items-center py-4">
                <h1 className="text-2xl font-bold text-center">Paid Orders Managment</h1>
            </div>
            <div className="relative overflow-hidden w-8/12 p-0 border-[1px] border-primary rounded-full my-3 mx-auto">
                <input
                    type="text"
                    className="placeholder-secondary border-none px-6 pl-10 md:text-sm text-[8px] font-mono input py-4 w-full rounded-full text-secondary"
                    value={filter}
                    placeholder="Search By Order Id"
                    onChange={searchEvent.bind(this)}
                />
                <h1 className=' absolute top-1/3 left-4 text-primary'><BsSearch /></h1>
            </div>
            <div className="">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono">
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Order ID
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Price
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Paid
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Delivery Id
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Okay
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {reverse?.map((item) => (
                                    <tbody key={item._id}>
                                        <tr className="font-mono">
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                {item?._id}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <input
                                                    type="number"
                                                    onChange={(e) => handleNewPriceChange(e, item._id)}
                                                />
                                            </td>
                                            <td onClick={() => handlePriceUpdate(item?._id, item?.discountedTotal, item?.lastPrice, item?.status)}
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                Paid
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <input
                                                    type="text"
                                                    onChange={(e) => handleDeliveryChange(e, item._id)}
                                                />
                                            </td>
                                            <td onClick={() => handleDeliveryClick(item?._id)}
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                Ok
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <div className=" justify-center flex items-center gap-2">

                                                    <h1 onClick={() => handleNavigateOrders(item?._id)}>Show</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}

                            </table>
                            <div className="flex items-center justify-end w-full mt-2">
                                <div>
                                    <h1>Total Sell - {totalSellPrice} taka</h1>
                                    <h1>Total Benifit - {totalBenifits} taka</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PaidOrder;
