import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import auth from '../../../../firebase.init';
import { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
const cloudinaryCloudName = 'dzhlxua9i';
const cloudinaryApiKey = '631779814765246';
const cloudinaryTopImgUploadPreset = 'ecommerce_product_images';
const cloudinaryMultipleImgUploadPreset = 'ecommerce_multiple_images';

const AddProducts = () => {
    const [user] = useAuthState(auth);

    const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).replace(/,/g, '');

    const [formData, setFormData] = useState({
        productName: '',
        stock: 0,
        description: '',
        sellPrice: 0,
        purchasePrice: 0,
        category: '',
        subcategory: '',
        discount: 0,
        createdAt: currentDate,
        updatedAt: '',
        size: [],
        email: user?.email || '',
        topimg: null,
        img: [],
        productCode: ''
    });

    const handleInputChange = (e) => {
        const { name, options } = e.target;
        if (name === 'size') {
            const selectedOptions = Array.from(options)
                .filter((option) => option?.selected)
                .map((option) => option?.value);
            setFormData({
                ...formData,
                size: [...formData.size, ...selectedOptions],
            });
        } else if (name === 'category') {
            const selectedCategory = e.target.value;
            setFormData({
                ...formData,
                category: selectedCategory,
            });
        } else if (name === 'subcategory') {
            const selectedCategory = e.target.value;
            setFormData({
                ...formData,
                subcategory: selectedCategory,
            });
        }
        else {
            setFormData({ ...formData, [name]: e.target.value });
        }
    };


    const removeSelectedSize = (sizeToRemove) => {
        setFormData({
            ...formData,
            size: formData?.size?.filter((size) => size !== sizeToRemove),
        });
    };

    const navigate = useNavigate()

    const uploadImageToCloudinary = async (image, cloudinaryUploadPreset) => {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('upload_preset', cloudinaryUploadPreset);
        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                console.error('Cloudinary Response:', response); // Log the response
                throw new Error('Image upload to Cloudinary failed');
            }
            const data = await response.json();
            return data?.url; // Return the URL of the uploaded image
        } catch (error) {
            console.error('Error uploading image to Cloudinary:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Upload top image and get the URL
        let topImageUrl = '';
        if (formData?.topimg) {
            try {
                topImageUrl = await uploadImageToCloudinary(formData?.topimg, cloudinaryTopImgUploadPreset);
            } catch (error) {
                console.error('Error uploading top image:', error);
                toast.error('Error uploading top image');
                return; // Stop processing if the top image upload fails
            }
        }

        // Upload multiple images and get their URLs
        const imageUrls = [];
        if (formData.img.length > 0) {
            for (const image of formData.img) {
                try {
                    const imageUrl = await uploadImageToCloudinary(image, cloudinaryMultipleImgUploadPreset);
                    imageUrls.push(imageUrl);
                } catch (error) {
                    console.error('Error uploading multiple images:', error);
                    toast.error('Error uploading multiple images');
                }
            }
        }
        const dataToSend = {
            ...formData,
            topimg: topImageUrl,
            img: imageUrls,
        };

        fetch('https://api.rebzigo.com/products', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                navigate(`/dashboard/AllProducts`);
                toast.success('Product successfully Add', data);
            })
            .catch((error) => {
                console.error('Error sending data:', error);
                toast.error('Error sending data');
            });
    };

    const handleSingleImageUpload = (e) => {
        const { name, files } = e?.target;
        setFormData({
            ...formData,
            [name]: files[0],
        });
    };

    const handleMultipleImagesUpload = (e) => {
        const { name, files } = e?.target;
        setFormData({
            ...formData,
            [name]: files,
        });
    };

    const [category, setCategory] = useState([]);
    const [sizes, setSizes] = useState([]);
    useEffect(() => {
        const sizeUrl = 'https://api.rebzigo.com/size';
        const categoryUrl = 'https://api.rebzigo.com/category';

        fetch(sizeUrl)
            .then(res => res.json())
            .then(data => setSizes(data))
            .catch(error => console.error('Error fetching sizes:', error));

        fetch(categoryUrl)
            .then(res => res.json())
            .then(data => setCategory(data))
    }, []);
    return (
        <div className="p-4 bg-gray-100">
            <form onSubmit={handleSubmit} className=" w-11/12 mx-auto bg-white p-4 shadow-md rounded-lg">
                <h2 className="text-2xl font-semibold mb-4">Add a New Product</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-600 font-semibold mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={user?.email || formData.email}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="productName" className="block text-gray-600 font-semibold mb-2">Product Name</label>
                        <input
                            type="text"
                            id="productName"
                            name="productName"
                            value={formData.productName}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="stock" className="block text-gray-600 font-semibold mb-2">Stock</label>
                        <input
                            type="number"
                            id="stock"
                            name="stock"
                            value={formData.stock}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-600 font-semibold mb-2">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="sellPrice" className="block text-gray-600 font-semibold mb-2">Sell Price</label>
                        <input
                            type="number"
                            id="sellPrice"
                            name="sellPrice"
                            value={formData.sellPrice}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="purchasePrice" className="block text-gray-600 font-semibold mb-2">Purchase Price</label>
                        <input
                            type="number"
                            id="purchasePrice"
                            name="purchasePrice"
                            value={formData.purchasePrice}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="subcategory" className="block text-gray-600 font-semibold mb-2">
                            Sub Category
                        </label>
                        <select
                            className="w-full border border-gray-300 rounded-lg p-2"
                            id='subcategory'
                            name="subcategory"
                            value={formData?.subcategory}
                            onChange={handleInputChange}
                            placeholder="Select a Sub Category"
                        >
                            <option value="">Select a Sub Category</option>
                            {category.map((cat) => (
                                <option key={cat?._id} value={cat?.subCategory}>
                                    {cat?.subCategory}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="category" className="block text-gray-600 font-semibold mb-2">
                            Category
                        </label>
                        <select
                            className="w-full border border-gray-300 rounded-lg p-2"
                            id='category'
                            name="category"
                            value={formData?.category}
                            onChange={handleInputChange}
                            placeholder="Select a Category"
                        >
                            <option value="">Select a Category</option>
                            {category
                                .filter((cat, index, self) =>
                                    index === self.findIndex((c) => c.category === cat.category)
                                )
                                .map((cat) => (
                                    <option key={cat?._id} value={cat?.category}>
                                        {cat?.category}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="discount" className="block text-gray-600 font-semibold mb-2">Discount</label>
                        <input
                            type="number"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                        />
                    </div>
                    <div className="mb-4">
                        <div className='flex justify-between items-center'>
                            <label htmlFor="size" className="block text-gray-600 font-semibold mb-2">
                                Size
                            </label>
                            <div>
                                <Link to='/dashboard/addSize' className='text-2xl'><FaPlus /></Link>
                            </div>
                        </div>
                        <select
                            id="size"
                            name="size"
                            multiple={true}
                            required
                            value={formData.size}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                        >
                            {sizes && sizes.map((size) => (
                                <option disabled={formData?.size?.includes(size?.size)} key={size?._id} value={size?.size}>
                                    {size?.size}
                                </option>
                            ))}
                        </select>
                        <div className="mt-2">
                            {formData.size.map((size) => (
                                <span
                                    key={size}
                                    className="inline-block bg-blue-200 text-blue-800 text-sm font-semibold px-2 py-1 mx-1 rounded-full"
                                >
                                    {size}
                                    <button
                                        type="button"
                                        onClick={() => removeSelectedSize(size)}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                    >
                                        &#x2715;
                                    </button>
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="productCode" className="block text-gray-600 font-semibold mb-2">Product Code</label>
                        <input
                            type="text"
                            id="productCode"
                            name="productCode"
                            required
                            value={formData.productCode}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 rounded-lg p-2"
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="topimg" className="block text-gray-600 font-semibold mb-2">Top Image Upload</label>
                    <input
                        type="file"
                        id="topimg"
                        name="topimg"
                        onChange={handleSingleImageUpload}
                        className="w-full border border-gray-300 rounded-lg p-2"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="multipleImages" className="block text-gray-600 font-semibold mb-2">Detail Images Upload</label>
                    <input
                        type="file"
                        id="multipleImages"
                        name="img"
                        multiple
                        onChange={handleMultipleImagesUpload}
                        className="w-full border border-gray-300 rounded-lg p-2"
                    />
                </div>

                <button type="submit" className="w-full bg-blue-500 text-white font-semibold rounded-lg p-2 hover:bg-blue-600 mt-4">
                    Add Product
                </button>
            </form>
        </div>
    );
};

export default AddProducts;
