import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const ReqestProducts = () => {
    const [user, setUser] = useState([])
    useEffect(() => {
        const url = 'https://api.rebzigo.com/reqestProducts';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setUser(data)
            })
    }, [user])

    const handleDelete = (id) => {
        const proced = window.confirm("Are Your Sure Delete This Request");
        if (proced) {
            const url = `https://api.rebzigo.com/reqestProducts/${id}`;
            fetch(url, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {

                    const reamingData = user.filter((user) => user._id !== id);
                    setUser(reamingData);
                    toast.success("Succesfully Delete Product Request");
                });
        }
    };
    return (
        <section className="my-4">
            <h1 className="text-2xl font-bold text-center mb-4">Request Products Managment</h1>
            <div className="w-11/12 mx-auto">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono ">
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               border-l border-transparent
                               font-mono
                               "
                                        >
                                            Product Name
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                                        >
                                            Product Code
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                                        >
                                            Phone
                                        </th>

                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            size
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Address
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >handleDetails
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {user.map((item) => (
                                    <tbody key={item._id}>
                                        <tr className="font-mono">
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-l border-primary
                               "
                                            >
                                                <Link to={`/products/${item?.id}`}> {item?.productName}</Link>
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.productcode}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.phone}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.quantity}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.size}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.district}, {item?.upzila}
                                            </td>

                                            <td
                                                onClick={() => handleDelete(item._id)}
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <span
                                                    className="
                                   border-primary
                                  py-2
                                  px-2
                                  text-primary
                                  inline-block
                                  rounded
                                  hover:bg-primary hover:text-white
                                  "
                                                >
                                                    Delete
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReqestProducts;
