import { useEffect, useState } from 'react';

const useNumber = () => {
    const [showNumber, setShowNumber] = useState([])
    useEffect(() => {
        const url = 'https://api.rebzigo.com/pmNumber';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setShowNumber(data)
            })
    }, [])
    return [showNumber]
};

export default useNumber;