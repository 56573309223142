import { RouterProvider } from 'react-router-dom';
import routes from './routes/routes';
import "./style.css"
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MessengerChat from './shared/MessengerChat';
function App() {
  return (
    <div className="">
      <ToastContainer position="top-center"
        autoClose={2000} theme="dark" />
      <RouterProvider router={routes} />
      <MessengerChat />
    </div>
  );
}

export default App;
