import { Link } from "react-router-dom";

const PaidOrderCard = ({ paidOrder }) => {

    return (
        <div className="px-6">
            <div className="bg-white w-[300px] mt-10 ml-auto text-center py-5">
                <h1 className="">Complete Orders</h1>
                <h1>{paidOrder?.length}</h1>
                <Link to='/dashboard/completeOrder' className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white'>Show</Link>
            </div>
        </div>
    );
};

export default PaidOrderCard;