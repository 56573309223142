import React, { useEffect, useState } from 'react';
import Card from '../../components/ProductsCard/Card';
import Loading from '../../shared/Loading';
import { useSelector } from 'react-redux';
import Navbar from '../../layout/main/Navbar';
import Footer from '../../shared/Footer';

const NewProducts = () => {
    const [products, setProducts] = useState([]);
    const [visibleProducts, setVisibleProducts] = useState(40);
    const [hasMoreData, setHasMoreData] = useState(true);
    const searchFilter = useSelector((state) => state.search)
    const [loading, setLoading] = useState(true);
    const categoryItems = useSelector((state) => state.categories);
    const loadProducts = () => {
        const url = "https://api.rebzigo.com/product";
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setProducts(data);
                setLoading(false)
                if (data.length <= visibleProducts) {
                    setHasMoreData(false);
                }
            });
    };
    useEffect(() => {
        loadProducts();
        const interval = setInterval(() => {
            loadProducts();
        }, 3000);
        return () => clearInterval(interval);
    }, [visibleProducts]);

    // ...

    const searchProducts = products.filter((item) => {
        const searchData = Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchFilter.toLowerCase())
        );
        return searchData;
    });
    const loadMore = () => {
        setVisibleProducts(visibleProducts + 40);
    };
    if (loading) {
        return <Loading />
    }
    const reverse = [...searchProducts].reverse();
    return (
        <>
            <Navbar />
            <div className='bg-gray-100 pt-10'>
                <div className='mt-5 w-11/12 md:w-11/12 lg:w-11/12 mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6'>
                    {
                        reverse?.slice(0, visibleProducts).map(product => <>
                            <Card product={product}></Card>
                        </>)
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NewProducts;