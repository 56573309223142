import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillYoutube } from "react-icons/ai";
import { FaLinkedin } from 'react-icons/fa';
const Footer = () => {
    const navigate = useNavigate()

    const goHome = () => {
        navigate("/")
    }


    return (
        <div>
            <footer className="bg-primary">
                <div className="px-4 py-16 mx-auto w-11/12">
                    <div className="lg:flex justify-center lg:gap-8">
                        <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 ">
                            <div className="pt-6 border-t col-span-2 sm:col-span-1 border-white">
                                <p className="font-bold text-white"> Information </p>

                                <nav className="flex flex-col mt-6 text-sm text-white space-y-4">
                                    <Link to="/" className="inline-block" > Home </Link>
                                    <Link to="/about" className="inline-block" > About Us </Link>
                                    <Link to="/blogs" className="inline-block" > Blogs </Link>
                                    <Link to="/categories" className="inline-block" > Shop </Link>
                                </nav>
                            </div>

                            <div className="pt-6 border-t col-span-2 sm:col-span-1 border-white ">
                                <p className="font-bold text-white"> Contact Us </p>

                                <nav className="flex flex-col mt-6 text-sm text-white space-y-4">
                                    <a className="inline-block" href="#">support@rebzigo.com</a>
                                    <a className="inline-block" href="#"> Phone +880182****25</a>
                                </nav>
                            </div>

                            <div className="pt-6 border-t col-span-2 sm:col-span-1 border-white ">
                                <p className="font-bold text-white"> Another </p>

                                <nav className="flex flex-col mt-6 text-sm text-white space-y-4">

                                </nav>
                            </div>

                            <div className="flex flex-wrap gap-5 mx-auto w-full">
                                <p className='text-white'>Payment Method</p>
                                <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                        <img className='md:w-32 w-28 h-16  md:h-20 mb-4' src="https://static.vecteezy.com/system/resources/previews/002/952/794/original/cash-on-delivery-steacker-free-vector.jpg" alt="" />
                                        <img className='md:w-32 h-16  md:h-20' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNjUT1aDf58ol4MsTblmfhk3WuThbQm7vZfF3gXBq_I2H58WT_ZGlRxmuMIPpCmrP3Kxk&usqp=CAU" alt="" />
                                    </div>
                                    <div className=''>
                                        <img className='md:w-32 w-28 h-16  md:h-20 mb-4' src="https://www.observerbd.com/2020/02/25/observerbd.com_1582635661.jpg" alt="" />
                                        <img className='md:w-32 w-28 h-16  md:h-20' src="https://img.lovepik.com/photo/40006/1643.jpg_wh860.jpg" alt="" />
                                    </div>
                                </div>

                            </div>

                            <div className="flex text-white col-span-2 space-x-4 lg:col-span-5">

                            </div>
                        </div>
                    </div>

                </div>
            </footer>
            <div>
                <div className='bg-white py-2'>
                    <div className='flex justify-between text-black w-8/12 mx-auto'>
                        <a className="hover:opacity-75" href="https://www.facebook.com/rebzigo" target="_blank" rel="noreferrer">
                            <span className="sr-only"> Facebook </span>

                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                            </svg>
                        </a>

                        <a className="hover:opacity-75" href="https://www.facebook.com/rebzigo" target="_blank" rel="noreferrer">
                            <span className="sr-only"> Instagram </span>

                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                            </svg>
                        </a>

                        <a className="hover:opacity-75" href="https://www.facebook.com/rebzigo" target="_blank" rel="noreferrer">
                            <span className="sr-only"> Twitter </span>

                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                        </a>

                        <a className="hover:opacity-75" href="https://www.youtube.com/@Rebzigo" target="_blank" rel="noreferrer">
                            <span className="sr-only"> Youtube </span>

                            <AiFillYoutube size={24} />
                        </a>

                        <a className="hover:opacity-75" href="https://www.facebook.com/rebzigo" target="_blank" >
                            <span className="sr-only"> Linkdin </span>

                            <FaLinkedin size={24} />
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <h1 className='md:text-xl text-md py-1 text-black text-center'>Copyright 2024 Rebzigo</h1>
            </div>
        </div>
    );
};

export default Footer;