import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CopyEmailCell from "./CopyEmailCell";
import { BsSearch } from "react-icons/bs";
const User = () => {
  const [user, setUser] = useState([])
  const [filter, setFilter] = useState("");
  useEffect(() => {
    const url = 'https://api.rebzigo.com/newUser';
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setUser(data)
      })
  }, [user])

  const searchEvent = (event) => {
    setFilter(event.target.value);
  };

  const dataSearch = user?.filter((item) => {
    return Object?.keys(item)?.some((key) => {
      const value = item[key];
      return (
        value !== null &&
        value !== undefined &&
        value
          .toString()
          .toLowerCase()
          .includes(filter.toString().toLowerCase())
      );
    });
  });
  const handleMakeEmployee = (email) => {
    const proced = window.confirm("Are you sure you want to add your employee");
    const url = `https://api.rebzigo.com/user/employee/${email}`;
    if (proced) {
      fetch(url, {
        method: "PUT",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.updated) {
            const updatedUserList = user.map((item) => {
              if (item.email === email) {
                return { ...item, role: "employee" };
              }
              return item;
            });
            setUser(updatedUserList);
          }
          toast.success("User role updated to 'employee'");
        });
    }
  };
  const copyEmails = () => {
    const emails = user?.map((item) => item.email).join(",");
    navigator.clipboard.writeText(emails);
    toast.success("Emails copied to clipboard");
  };
  const handleUnemployed = (email) => {
    const proced = window.confirm("Are you sure you want to add your employee");
    const url = `https://api.rebzigo.com/user/UnEmployee/${email}`;
    if (proced) {
      fetch(url, {
        method: "PUT",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.updated) {
            const updatedUserList = user.map((item) => {
              if (item.email === email) {
                return { ...item, role: "user" };
              }
              return item;
            });
            setUser(updatedUserList);
          }
          toast.success("User role updated to employee");
        });
    }
  }

  return (
    <section className="my-4">
      <div className="flex justify-between items-center w-10/12 mx-auto my-4">
        <h1 className="text-2xl font-bold text-center mb-4">User Managment</h1>
        <button onClick={copyEmails} className="border-[1px] border-black px-6 py-3 rounded-md">Copy Email</button>
      </div>
      <div className="relative overflow-hidden mb-3  w-8/12 p-0 border-[1px] border-primary rounded-full mx-auto">
        <input
          type="text"
          className="placeholder-secondary border-none px-6 pl-10 md:text-sm text-[8px] font-mono input py-4 w-full rounded-full text-secondary"
          value={filter}
          placeholder="Search Your Products"
          onChange={searchEvent.bind(this)}
        />
        <h1 className=' absolute top-1/3 left-4 text-primary'><BsSearch /></h1>
      </div>
      <div className="w-11/12 mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="max-w-full overflow-x-auto rounded-md">
              <table className="table-auto w-full">
                <thead>
                  <tr className="bg-primary/40 text-center font-mono ">
                    <th
                      className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               border-l border-transparent
                               font-mono
                               "
                    >
                      Name
                    </th>
                    <th
                      className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                    >
                      Email
                    </th>
                    <th
                      className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                    >
                      Employee
                    </th>
                  </tr>
                </thead>
                {dataSearch?.map((item) => (
                  <tbody>
                    <tr className="font-mono">
                      <td
                        className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-l border-primary
                               "
                      >
                        {item?.name}
                      </td>
                      <td
                        className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                      >
                        <CopyEmailCell email={item?.email} />
                      </td>
                      {
                        item?.role === 'employee' ? <td onClick={() => handleUnemployed(item?.email)} className="border-b-[1px] border-primary cursor-pointer"><h1 className="text-center">Employeed</h1></td> : <td onClick={() => handleMakeEmployee(item?.email)} className="border-b-[1px] border-primary"

                        >
                          <h1 className="
                               text-center
                               font-medium
                              py-2 m-4
                               px-1  
                               border-b transition duration-300 cursor-pointer
                               ">Make Employee</h1>
                        </td>
                      }
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default User;
