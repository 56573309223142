import React, { useEffect, useState } from 'react';
import Navbar from '../layout/main/Navbar';
import Card from '../components/ProductsCard/Card';
import noProduct from '../Assets/noProduct.jpg';
import { useSelector } from 'react-redux';
import Loading from '../shared/Loading';
import Footer from '../shared/Footer';

const Categories = () => {
    const [products, setProducts] = useState([]);
    const [visibleProducts, setVisibleProducts] = useState(52);
    const [filterItem, setfilterItems] = useState([]);
    const categoryItems = useSelector((state) => state.categories);
    const [categorySelect, setCategorySelect] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedMainCategory, setSelectedMainCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const searchValue = useSelector((state) => state.search);
    useEffect(() => {
        const url = 'https://api.rebzigo.com/product';
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setProducts(data);
                setfilterItems(data);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (categoryItems) {
            setCategorySelect(categoryItems);
        } else {
            setCategorySelect(null);
        }
    }, [categoryItems]);

    useEffect(() => {
        filterProducts();
    }, [categorySelect, products, searchValue]);

    const filterProducts = () => {
        let updatedItems = products;
        // Category filtering
        if (categorySelect?.length) {
            updatedItems = updatedItems.filter((item) => item?.category === categorySelect);
        }
        // Search filtering
        if (searchValue) {
            updatedItems = updatedItems.filter((item) =>
                Object.values(item)
                    .some((value) =>
                        typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
                    )
            );
        }
        setfilterItems(updatedItems);
        setVisibleProducts(40);
    };

    const [category, setCategory] = useState([]);
    useEffect(() => {
        const url = 'https://api.rebzigo.com/category';
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setCategory(data);
            });
    }, []);

    const categoryTree = {};
    category.forEach((item) => {
        const { category, subCategory } = item;
        if (!categoryTree[category]) {
            categoryTree[category] = { subcategories: [subCategory] };
        } else {
            categoryTree[category].subcategories.push(subCategory);
        }
    });

    const handleCheckboxClick = () => {
        setIsMenuOpen(false);
    };

    const handleMainCategoryClick = (mainCategory) => {
        setSelectedMainCategory(mainCategory);
        setSelectedSubCategory(null);
        filterMainCategory(mainCategory);
    };

    const handleSubCategoryChange = (subCategory) => {
        setSelectedSubCategory(subCategory);
        filterCategory(subCategory);
    };

    const filterCategory = (subCategory) => {
        const updatedItems = products?.filter((item) => item?.subcategory === subCategory);
        setfilterItems(updatedItems);
    };

    const filterMainCategory = (category) => {
        const updatedItems = products?.filter((item) => item?.category === category);
        setfilterItems(updatedItems);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Navbar />
            <section className="py-3 md:py-6">
                <div className="px-4 mx-auto w-11/12 lg:py-6 md:px-6">
                    <div className="flex flex-wrap md:mb-10 mb-4 -mx-3">
                        <div className="w-full lg:w-1/4 lg:block">
                            {/* Hamburger Menu Button */}
                            <div className="lg:hidden mb-2 md:mb-0">
                                <button
                                    className="text-2xl font-bold p-2"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    ☰ Categories
                                </button>
                            </div>

                            {/* Sidebar Content */}
                            <div className={`p-4 mb-5 bg-white border border-gray-200 ${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
                                <h2 className="text-2xl font-bold">Product Categories</h2>
                                <div className="w-16 pb-2 mb-6 border-b border-rose-600"></div>
                                <ul>
                                    {Object.entries(categoryTree).map(([mainCategory, data]) => (
                                        <li key={mainCategory}>
                                            <h1
                                                onClick={() => {
                                                    handleCheckboxClick(); handleMainCategoryClick(mainCategory)
                                                }}
                                                className={`text-lg cursor-pointer font-semibold tracking-wider ${selectedMainCategory === mainCategory ? 'text-rose-600' : ''}`}
                                            >
                                                {mainCategory}
                                            </h1>
                                            <ul className="ml-2 flex flex-col my-2">
                                                {data?.subcategories?.map((subCategory) => (
                                                    <label key={subCategory} className="cursor-pointer ml-2">
                                                        <input
                                                            className="text-lg mb-2"
                                                            type="radio"
                                                            name="subcategory"
                                                            value={subCategory}
                                                            checked={selectedSubCategory === subCategory}
                                                            onChange={() => {
                                                                handleCheckboxClick()
                                                                handleSubCategoryChange(subCategory)
                                                            }}
                                                        />
                                                        <span className={`ml-2 tracking-wider ${selectedSubCategory === subCategory ? 'text-rose-600' : ''}`}>
                                                            {subCategory}
                                                        </span>
                                                    </label>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="w-full px-3 lg:w-3/4">
                            {filterItem.length === 0 ? (
                                <div>
                                    <img src={noProduct} alt="" />
                                </div>
                            ) : (
                                <div className="mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6">
                                    {filterItem.slice(0, visibleProducts).map((product) => (
                                        <Card key={product.id} product={product}></Card>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Categories;
