import { useCreateUserWithEmailAndPassword, useUpdateProfile } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import auth from '../firebase.init';
import useToken from '../hooks/useToken';
import { useState, useEffect } from 'react';
import Navbar from '../layout/main/Navbar';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';

const Registation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || '/';
    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useCreateUserWithEmailAndPassword(auth);
    const [updateProfile, updating, updateError] = useUpdateProfile(auth);
    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm();

    const [exituser, setExitUser] = useState([]);
    useEffect(() => {
        const url = 'https://api.rebzigo.com/new';
        fetch(url)
            .then(res => res.json())
            .then(data => setExitUser(data))
    }, [])


    const onSubmit = async (data) => {
        const name = data.name;
        const email = data.email;
        const password = data.password;
        const confirmPassword = data.confirmPassword;

        try {
            await createUserWithEmailAndPassword(email, password);
            await updateProfile({ displayName: name });
            reset();
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    toast.success('Registration Successful. Please check your email for verification.');
                    navigate('/verification');
                })
                .catch(error => {
                    toast.error(error.message);
                });
        } catch (error) {
            toast.error(error.message);
        }
    }
    const [token] = useToken(user);

    return (
        <div>
            <Navbar />
            <div className='flex items-center justify-center h-full w-11/12 mx-auto'>
                <form className="font-mono my-20" onSubmit={handleSubmit(onSubmit)}>
                    <h1 className='text-2xl tracking-wider font-bold text-center pb-10'>Register Here</h1>
                    <p className="text-red-500">{errors.name && <span>{errors.name.message}</span>}</p>
                    <div className="">
                        <h1>Full Name<span className='text-red-600'>*</span></h1>
                        <input
                            className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                            type="text"
                            placeholder="Enter Your Name"
                            {...register("name", {
                                required: 'Full Name is required*',
                            })}
                        />
                    </div>
                    <p className="text-red-500">{errors.email && <span>{errors.email.message}</span>}</p>
                    <div className="mt-2">
                        <h1>Email<span className='text-red-600'>*</span></h1>
                        <input
                            type="email"
                            className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                            placeholder="Enter Your Email"
                            {...register("email", {
                                required: 'Email is required*',
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Provide a Valid Email',
                                },
                            })}
                        />
                    </div>
                    <p className="text-red-500">{errors.password && <span>{errors.password.message}</span>}</p>
                    <div className="mt-2">
                        <h1>Password<span className='text-red-600'>*</span></h1>
                        <input
                            className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                            type="password"
                            placeholder="Enter Your Password"
                            {...register("password", {
                                required: 'Password is required*',
                                minLength: {
                                    value: 8,
                                    message: 'Enter At Least 8 Characters',
                                },
                            })}
                        />
                    </div>
                    <p className="text-red-500">{errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}</p>
                    <div className="mt-2">
                        <h1>Confirm Password<span className='text-red-600'>*</span></h1>
                        <input
                            type="password"
                            className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                            placeholder="Confirm Password"
                            {...register("confirmPassword", {
                                validate: (val) => {
                                    if (val !== watch('password')) {
                                        return 'Your passwords do not match';
                                    }
                                },
                            })}
                        />
                    </div>
                    <div className='flex items-center gap-5'>
                        <input type="submit" className="bg-primary text-md px-10 py-3 mt-6 hover:bg-transparent border-primary border hover:text-primary transition duration-300 rounded-lg text-white" value="Sign Up" />
                        <p className="ml-3 mt-8 text-secondary text-lg">
                            <Link className="ml-2 underline text-purple-600 cursor-pointer" to="/login">
                                Login
                            </Link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Registation;