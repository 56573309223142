import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CouponCodeEdit = () => {
    const { register, handleSubmit, setValue } = useForm();
    const navigate = useNavigate();
    const { id } = useParams();

    const [couponCode, setCouponCode] = useState({});
    useEffect(() => {
        fetch(`https://api.rebzigo.com/couponCode/${id}`)
            .then((response) => response.json())
            .then((data) => {
                setCouponCode(data);
                setValue('email', data.email);
                setValue('name', data.name);
                setValue('code', data.code);
                setValue('discount', data.discount);
            })
            .catch((error) => console.error('Error fetching coupon code: ', error));
    }, [id, setValue]);

    const onSubmit = async (data) => {
        try {
            const response = await fetch(`https://api.rebzigo.com/couponCode/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    name: data.name,
                    code: data.code,
                    discount: data.discount,
                }),
            });
            if (response.ok) {
                toast.success("Coupon Code Update Successful")
                navigate(`/dashboard/couponCode`);
            } else {
                // Handle error
                toast.error('Failed to update coupon code');
            }
        } catch (error) {
            console.error('Error updating coupon code: ', error);
        }
    };

    return (
        <div>
            <h1 className='mt-10 text-center text-2xl font-semibold tracking-wider'>Edit Coupon Code</h1>
            <form onSubmit={handleSubmit(onSubmit)} className='w-10/12 mx-auto mt-4'>
                <div>
                    <label htmlFor='email' className="font-mono text-secondary">Employee Email</label>
                    <input
                        id='email'
                        type="text"
                        placeholder="Enter Employee email.."
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary"
                        {...register("email")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='name' className="mt-3 font-mono text-secondary">Employee Name</label>
                    <input
                        id='name'
                        type="text"
                        placeholder="Enter employee name"
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary"
                        {...register("name")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='code' className="font-mono text-secondary mt-3">Coupon Code Name</label>
                    <input
                        id='code'
                        type="text"
                        placeholder="Add Coupon Code Name"
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary"
                        {...register("code")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='discount' className="mt-3 font-mono text-secondary">Add Discount</label>
                    <input
                        id='discount'
                        type="number"
                        placeholder="Enter Discount Number"
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary"
                        {...register("discount")}
                        required
                    />
                </div>
                <input type="submit" value="Update" className='text-md bg-primary text-white border border-primary hover:bg-transparent hover:text-primary rounded-md transition duration-300 w-full py-4 mt-4 cursor-pointer' />
            </form>
        </div>
    );
};

export default CouponCodeEdit;
