import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    products: [],
    total: 0,
    purchesTotal: 0,
};

// Load the cart from local storage
const loadCartFromLocalStorage = () => {
    const cartData = localStorage.getItem('cart');
    return cartData ? JSON.parse(cartData) : initialState;
};

// Save the cart to local storage
const saveCartToLocalStorage = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
};

const AddCartSlice = createSlice({
    name: 'cart',
    initialState: loadCartFromLocalStorage(),
    reducers: {
        AddToCart: (state, action) => {
            const { product } = action.payload;
            const { quantity } = product;

            const existingProductIndex = state.products.findIndex(
                (existingProduct) => existingProduct._id === product._id && existingProduct.size === product.size
            );

            if (existingProductIndex !== -1) {
                // Update quantity of existing product
                state.products = state.products.map((existingProduct, index) => {
                    if (index === existingProductIndex) {
                        return {
                            ...existingProduct,
                            quantity: existingProduct.quantity + quantity // Use the passed quantity
                        };
                    }
                    return existingProduct;
                });
            } else {
                // Add new product to the cart with the passed quantity
                state.products = [
                    ...state.products,
                    { ...product, quantity }
                ];
            }

            // Update total and purchaseTotal
            state.total = state.products.reduce(
                (totalPrice, product) => totalPrice + (parseFloat(product.sellPrice) * product.quantity),
                0
            );

            state.purchaseTotal = state.products.reduce(
                (totalPrice, product) => totalPrice + (parseFloat(product.purchasePrice) * product.quantity),
                0
            );

            // Save the cart to local storage
            saveCartToLocalStorage(state);
        },
        removeQuantity: (state, action) => {
            const existingProductIndex = state.products.findIndex(
                (product) => product._id === action.payload._id
            );

            if (existingProductIndex !== -1) {
                const existingProduct = state.products[existingProductIndex];
                if (existingProduct.quantity > 1) {
                    const newQuantity = existingProduct.quantity - 1;

                    // Update the product with the new quantity
                    const updatedProduct = {
                        ...existingProduct,
                        quantity: newQuantity,
                    };

                    const updatedProducts = [...state.products];
                    updatedProducts[existingProductIndex] = updatedProduct;

                    state.products = updatedProducts;

                    // Calculate and update the total price
                    state.total = state.products.reduce(
                        (totalPrice, product) => totalPrice + (product.sellPrice * product.quantity),
                        0
                    );
                    state.purchesTotal = state.products.reduce(
                        (totalPrice, product) => totalPrice + (product.purchasePrice * product.quantity),
                        0
                    );

                }
            }

            // Save the cart to local storage
            saveCartToLocalStorage(state);
        },
        updateQuantity: (state, action) => {
            const { _id, quantity } = action.payload;
            const existingProductIndex = state.products.findIndex(
                (product) => product._id === _id
            );

            if (existingProductIndex !== -1) {
                const existingProduct = state.products[existingProductIndex];

                // Update the product with the new quantity
                const updatedProduct = {
                    ...existingProduct,
                    quantity,
                };

                const updatedProducts = [...state.products];
                updatedProducts[existingProductIndex] = updatedProduct;

                state.products = updatedProducts;

                // Calculate and update the total price
                state.total = state.products.reduce(
                    (totalPrice, product) => totalPrice + (product.sellPrice * product.quantity),
                    0
                );

                state.purchesTotal = state.products.reduce(
                    (totalPrice, product) => totalPrice + (product.purchasePrice * product.quantity),
                    0
                );
            }

            // Save the cart to local storage
            saveCartToLocalStorage(state);
        },
        removeFromCart: (state, action) => {
            const { _id, size } = action.payload;
            state.products = state.products.filter(
                (product) => !(product._id === _id && product.size === size)
            );

            // Recalculate the total price
            state.total = state.products.reduce(
                (totalPrice, product) => totalPrice + (parseFloat(product.sellPrice) * product.quantity),
                0
            );

            // Recalculate the purchase total
            state.purchesTotal = state.products.reduce(
                (totalPrice, product) => totalPrice + (parseFloat(product.purchasePrice) * product.quantity),
                0
            );

            // Save the cart to local storage
            saveCartToLocalStorage(state);
        },
        clearCart: (state) => {
            state.products = [];
            state.total = 0;
            state.purchesTotal = 0;
            saveCartToLocalStorage(state);
        },
    },
});

export const { AddToCart, removeQuantity, clearCart, updateQuantity, removeFromCart } = AddCartSlice.actions;

export default AddCartSlice.reducer;
