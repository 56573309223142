import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import Modal from 'react-modal';
import auth from '../../firebase.init';

const ProductRequestModal = ({ isOpen, selectedSize, productsData, onRequestClose, handleProductRequest }) => {
    const [user] = useAuthState(auth)
    const [formData, setFormData] = useState(prevState => ({
        ...prevState,
        email: '' || user?.email,
        quantity: 1,
        size: selectedSize,
        phone: '',
        name: '',
        district: '',
        upzila: '',
        address: '',
        productName: productsData?.productName,
        id: productsData?._id,
        productcode: productsData?.productCode,
        productCategory: productsData?.category
    }));
    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            size: selectedSize
        }));
    }, [selectedSize]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (!formData.name || !formData.phone || !formData.district || !formData.upzila || !formData.address || !formData.quantity) {
            alert('Please fill in all required fields.');
            return;
        }
        handleProductRequest(formData);
        onRequestClose();
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Product Request Modal"
        >
            <button onClick={onRequestClose} className="close-button">
                &#x2715;
            </button>
            <h2 className='text-center text-xl font-semibold font-mono'>Product Request Form</h2>
            <form className='z-10 font-mono'>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email || user?.email}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Full Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone</label>
                    <input
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">District</label>
                    <input
                        type="text"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Upzila</label>
                    <input
                        type="text"
                        name="upzila"
                        value={formData.upzila}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Address</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Quantity:</label>
                    <input
                        type="number"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        min="1"
                        required
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <button className='mt-2 px-4 py-2 bg-primary text-white' type="button" onClick={handleSubmit}>
                        Submit Request
                    </button>
                </div>
            </form>
            <div>
                <div className='flex items-center justify-between w-full my-3 bg-[#d9dadb] px-4 py-2 rounded-md'>
                    <div>
                        <img src={productsData.topimg} className='w-16 h-14' alt="" />
                    </div>
                    <div>
                        <h1>Size : {selectedSize}</h1>
                        <h2>Quantity : {formData.quantity}</h2>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ProductRequestModal;
