/* eslint-disable no-undef */
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
const PendingOrder = () => {
    const [products, setProducts] = useState([]);
    const [newPrices, setNewPrices] = useState(0);
    const [showPdf,] = useState(false);
    const [refetchOrders, setRefetchOrders] = useState(false);
    const pdfRef = useRef();
    useEffect(() => {
        const url = "https://api.rebzigo.com/orders";
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const pendingOrder = data.filter(order => order.status === 'Pending');
                setProducts(pendingOrder)
            });
    }, [refetchOrders]);
    const navigate = useNavigate()
    const reverse = [...products].reverse()


    const handleNavigateOrders = (id) => {
        navigate(`/dashboard/Orders/${id}`)
    }
    // problem solved
    const handleNewPriceChange = (e, id) => {
        const { value } = e.target;
        setNewPrices(value)
    };

    const handlePriceUpdate = (orderId, price) => {
        const newPrice = newPrices
        if (parseFloat(newPrice) > parseFloat(price)) {
            toast.error(`Your Price is out of range, add price ${price}`);
        } else {
            fetch(`https://api.rebzigo.com/orders/${orderId}/updatePrice`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ newPrice }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.newPrice) {
                        toast.success("Price updated successfully");
                    } else {
                        toast.error("Failed to update price");
                    }
                })
                .catch((error) => {
                    toast.error("Failed to update price");
                });
        }
    };
    const handleStatusChange = (orderId, newStatus) => {
        fetch(`https://api.rebzigo.com/orders/${orderId}/updateStatus`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ newStatus }),
        })
            .then((res) => res.json())
            .then((data) => {
                const updatedOrders = products.map((order) => {
                    if (order._id === orderId) {
                        return { ...order, status: data.status };
                    }
                    return order;
                });
                setProducts(updatedOrders);
                setRefetchOrders(prevState => !prevState);
            })
    };

    const handleAllPdf = async () => {
        try {
            // Generate PDF
            const pdfDoc = await PDFDocument.create();

            for (const order of products) {
                const page = pdfDoc.addPage([595.28, 841.89]); // A4 size
                const { fullName, phone, district, upzila, address, email, discountedTotal, lastPrice, phone2, _id, orderedProducts, couponId } = order;
                const lastPrices = lastPrice ? parseFloat(lastPrice) : 0;

                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
                page.setFont(helveticaFont);
                page.setFontSize(12);
                const drawFixedWidthLabel = (text, x, y, width) => {
                    page.drawText(text, { x, y, width });
                };

                const drawVariableWidthContent = (text, x, y) => {
                    page.drawText(text, { x, y });
                };

                const fixedWidthLabel = (text, x, y, width) => {
                    drawFixedWidthLabel(text, x, y, width);
                };

                const variableWidthContent = (text, x, y) => {
                    drawVariableWidthContent(text, x, y);
                };
                // Add order details
                fixedWidthLabel(`Name                                   :`, 50, page.getHeight() - 50, 150);
                variableWidthContent(`${fullName}`, 270, page.getHeight() - 50);

                fixedWidthLabel(`Phone                                  :`, 50, page.getHeight() - 75, 150);
                variableWidthContent(`${phone}`, 270, page.getHeight() - 75);

                fixedWidthLabel(`Phone 2                               :`, 50, page.getHeight() - 100, 150);
                variableWidthContent(`${phone2}`, 270, page.getHeight() - 100);

                fixedWidthLabel(`District                                 :`, 50, page.getHeight() - 125, 150);
                variableWidthContent(`${district}`, 270, page.getHeight() - 125);

                fixedWidthLabel(`Upzila                                  :`, 50, page.getHeight() - 150, 150);
                variableWidthContent(`${upzila}`, 270, page.getHeight() - 150);

                fixedWidthLabel(`Address                               :`, 50, page.getHeight() - 175, 150);
                variableWidthContent(`${address}`, 270, page.getHeight() - 175);

                fixedWidthLabel(`Email                                   :`, 50, page.getHeight() - 200, 150);
                variableWidthContent(`${email}`, 270, page.getHeight() - 200);

                fixedWidthLabel(`Price                                    :`, 50, page.getHeight() - 225, 150);
                variableWidthContent(`${parseFloat(discountedTotal) - parseFloat(lastPrices)} tk`, 270, page.getHeight() - 225);

                fixedWidthLabel(`Order ID                              :`, 50, page.getHeight() - 250, 150);
                variableWidthContent(`${_id}`, 270, page.getHeight() - 250);

                const startY = page.getHeight() - 275;
                const cellWidth = 465;
                const cellHeight = 20;
                const borderWidth = 2;

                function drawTable(startY, headers, data) {
                    const tableStartY = startY;
                    const tableEndY = startY - (data.length + 1) * cellHeight;
                    const tableStartX = 50;
                    const tableEndX = tableStartX + cellWidth;

                    // Draw horizontal borders for header row
                    page.drawLine({
                        start: { x: tableStartX, y: tableStartY },
                        end: { x: tableEndX, y: tableStartY },
                        thickness: borderWidth,
                        color: rgb(0, 0, 0),
                    });

                    // Draw text for header row
                    headers.forEach((header, index) => {
                        page.drawText(header, { x: tableStartX + 5 + index * 100, y: tableStartY - 12 });
                    });

                    // Draw vertical borders for header row
                    headers.forEach((_, index) => {
                        page.drawLine({
                            start: { x: tableStartX + index * 100, y: tableStartY },
                            end: { x: tableStartX + index * 100, y: tableEndY },
                            thickness: borderWidth,
                            color: rgb(0, 0, 0),
                        });
                    });

                    // Draw horizontal borders and fill cells for data rows
                    data.forEach((rowData, rowIndex) => {
                        const cellY = tableStartY - (rowIndex + 1) * cellHeight;

                        // Draw horizontal border for each row
                        page.drawLine({
                            start: { x: tableStartX, y: cellY },
                            end: { x: tableEndX, y: cellY },
                            thickness: borderWidth,
                            color: rgb(0, 0, 0),
                        });

                        // Draw text inside the cell
                        rowData.forEach((cellData, cellIndex) => {
                            page.drawText(String(cellData), { x: tableStartX + 5 + cellIndex * 100, y: cellY - 12 });
                        });

                        // Draw vertical borders for data rows
                        headers.forEach((_, index) => {
                            page.drawLine({
                                start: { x: tableStartX + index * 100, y: cellY },
                                end: { x: tableStartX + index * 100, y: cellY - cellHeight },
                                thickness: borderWidth,
                                color: rgb(0, 0, 0),
                            });
                        });
                    });

                    // Draw bottom horizontal border for the last row
                    page.drawLine({
                        start: { x: tableStartX, y: tableEndY },  // Use tableEndY as y-coordinate
                        end: { x: tableEndX, y: tableEndY },  // Use tableEndY as y-coordinate
                        thickness: borderWidth,
                        color: rgb(0, 0, 0),
                    });

                    // Draw right bottom corner
                    page.drawLine({
                        start: { x: tableEndX, y: tableStartY },
                        end: { x: tableEndX, y: tableEndY },
                        thickness: borderWidth,
                        color: rgb(0, 0, 0),
                    });
                }



                // Draw the table with headers and data
                drawTable(startY, ["SL", "Code", "Price", "Size", "Quantity"], orderedProducts.map((product, index) => [
                    index + 1,
                    product.code ? product.code : " No Code",
                    String(product.sellPrice),
                    product.size,
                    String(product.quantity)
                ]));



                // Example usage for a label with fixed width and content with variable width
                fixedWidthLabel(`Name                                   :`, 50, page.getHeight() - 450, 570);
                variableWidthContent(`${fullName}`, 270, page.getHeight() - 450);

                fixedWidthLabel(`Phone                                  :`, 50, page.getHeight() - 475, 70);
                variableWidthContent(`${phone}`, 270, page.getHeight() - 475);

                fixedWidthLabel(`Phone 2                               :`, 50, page.getHeight() - 500, 70);
                variableWidthContent(`${phone2}`, 270, page.getHeight() - 500);

                fixedWidthLabel(`District                                 :`, 50, page.getHeight() - 525, 70);
                variableWidthContent(`${district}`, 270, page.getHeight() - 525);

                fixedWidthLabel(`Upzila                                  :`, 50, page.getHeight() - 550, 70);
                variableWidthContent(`${upzila}`, 270, page.getHeight() - 550);

                fixedWidthLabel(`Address                               :`, 50, page.getHeight() - 575, 70);
                variableWidthContent(`${address}`, 270, page.getHeight() - 575);

                fixedWidthLabel(`Email                                   :`, 50, page.getHeight() - 600, 70);
                variableWidthContent(`${email}`, 270, page.getHeight() - 600);

                // Price
                fixedWidthLabel(`Price                                    :`, 50, page.getHeight() - 625, 70);
                variableWidthContent(` ${parseFloat(discountedTotal)} - ${lastPrices} = ${parseFloat(discountedTotal) - parseFloat(lastPrices)}`, 270, page.getHeight() - 625);

                // Coupon details
                fixedWidthLabel(`Coupon Coder Name          :`, 50, page.getHeight() - 650, 150);
                variableWidthContent(`${couponId?.name}`, 270, page.getHeight() - 650);

                fixedWidthLabel(`Coupon Code Discount       :`, 50, page.getHeight() - 675, 150);
                variableWidthContent(`${couponId?.discount ? couponId?.discount : 0}%`, 270, page.getHeight() - 675);

                fixedWidthLabel(`Coupon Code                      :`, 50, page.getHeight() - 700, 150);
                variableWidthContent(`${couponId?.code}`, 270, page.getHeight() - 700);

                // Order ID
                fixedWidthLabel(`Order ID                              :`, 50, page.getHeight() - 725, 70);
                variableWidthContent(`${_id}`, 270, page.getHeight() - 725);


            };
            const pdfBytes = await pdfDoc.save();
            // Download PDF
            const blob = new Blob([pdfBytes], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "pending_orders.pdf";
            link.click();
            products?.forEach((product) => {
                handleStatusChange(product?._id, "Shipping");
            });
            toast.success("PDF generated successfully");
        } catch (error) {
            toast.error("Failed to generate PDF");
        }
    };

    return (
        <section className="my-4">
            <div className="flex justify-around items-center py-4">
                <h1 className="text-2xl font-bold text-center mb-4">Pending Orders Managment</h1>
                <button onClick={handleAllPdf} className=" uppercase text-sm px-4 py-3 bg-black text-white rounded-md">  {showPdf ? 'Hide Pdf' : 'All Pdf'}</button>
                <a ref={pdfRef} style={{ display: "none" }} />
            </div>
            <div className="">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono ">
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Transition ID
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Last Price
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Payment Method
                                        </th>

                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {reverse?.map((item) => (
                                    <tbody key={item._id}>
                                        <tr className="font-mono">
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                {item?.transactionId}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <input
                                                    type="number"
                                                    onChange={(e) => handleNewPriceChange(e, item._id, item?.discountedTotal)}
                                                />

                                            </td>
                                            <td onClick={() => handlePriceUpdate(item?._id, item?.discountedTotal)}
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                {item?.paymentMethod}
                                            </td>

                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <div className=" justify-center flex items-center gap-2">

                                                    <h1 onClick={() => handleNavigateOrders(item?._id)}>Details</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PendingOrder;
