import React, { useEffect } from 'react';
import { RiUserSharedFill } from 'react-icons/ri';
import { FaCartArrowDown } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from '../../components/ui/SearchBar';
import logo from "../../Assets/logo.png"
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import UseAdmin from '../../hooks/UseAdmin';
import UseEmployee from '../../hooks/UseEmployee';
const Navbar = () => {
    const [user] = useAuthState(auth)
    const [admin] = UseAdmin(user);
    const [employee] = UseEmployee(user)
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const logout = () => {
        signOut(auth);
        navigate('/login')
    }


    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleAvatarHover = () => {
        if (!isDropdownOpen) {
            toggleDropdown();
        }
    };

    const handleAvatarClick = (e) => {
        e.preventDefault();
        toggleDropdown();
    };
    const { products } = useSelector(state => state?.AddToCart)


    return (
        <div className='bg-white'>  <div onClick={handleAvatarClick}
            className='grid grid-cols-3 md:w-11/12 w-full mx-auto md:py-2 items-center py-0 bg-white'>
            <div className=' cursor-pointer'>
                <Link to="/">
                    <h1 className='custom uppercase md:text-3xl text-lg  tracking-wider font-bold ml-2 text-black'>Rebzigo</h1>
                </Link>
            </div>
            <div className='my-1 md:my-0 mr-2'>
                <SearchBar />
            </div>
            <div className='flex items-center md:gap-10 gap-4  mx-auto justify-end w-11/12'>
                <div>
                    <div className='md:block hidden'>
                        <Link to="/cart" className=' md:px-7 px-5 rounded-full py-3 tracking-wider flex items-center gap-1 text-black'><span><FaCartArrowDown className='md:text-4xl text-xl ' /></span> <span className=''> ({products?.length})</span> </Link>
                    </div>
                    <Link to="/cart" className='md:hidden block relative'><FaCartArrowDown className='md:text-2xl text-xl' /><span className='text-[15px] absolute -bottom-3 -left-2'> ({products?.length})</span></Link>
                </div>

                {
                    user?.emailVerified === true ? <div className="relative inline-block mr-3 mt-[5px]">
                        <button
                            className="transition-all duration-300 uppercase"
                            onClick={handleAvatarClick}
                            onMouseEnter={handleAvatarHover}
                        >
                            <div className="avatar">
                                <div className="md:w-12 w-6 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                    {user?.photoURL ? (
                                        <img src={user?.photoURL} alt={user?.displayName} className="md:w-12 w-6 p-0 text-primary" />
                                    ) : (
                                        <img
                                            src="https://i.stack.imgur.com/l60Hf.png"
                                            alt={user?.displayName}
                                            className="w-14 text-primary"
                                        />
                                    )}
                                </div>
                            </div>
                        </button>
                        {isDropdownOpen && (
                            <div className="absolute z-10 right-0 mt-2">
                                <ul className="dropdown-content menu shadow drop-bg rounded">
                                    <div className="uppercase w-48 max-w-screen origin-center appear-done enter-done bg-white pl-4 rounded-md">
                                        <>
                                            {
                                                employee && <Link
                                                    to="/myProfile"
                                                    className="flex items-center px-3 py-3 cursor-pointer text-primary text-sm focus:outline-none hover:bg-primary hover:text-white rounded-md"
                                                >
                                                    My Profile
                                                </Link>
                                            }
                                            <Link
                                                to="/myOrders"
                                                className="flex items-center px-3 py-3 cursor-pointer text-primary text-sm focus:outline-none hover:bg-primary hover:text-white rounded-md"
                                            >
                                                My Orders
                                            </Link>
                                            {
                                                admin || employee ? <Link
                                                    to="/dashboard"
                                                    className="flex items-center px-3 py-3 cursor-pointer text-primary text-sm focus:outline-none hover:bg-primary hover:text-white rounded-md"
                                                >
                                                    Dashbord
                                                </Link> : ""
                                            }
                                        </>
                                        <button
                                            className="flex w-full items-center px-3 py-3 cursor-pointer text-primary text-sm focus:outline-none hover:bg-primary hover:text-white rounded-md"
                                            onClick={logout}
                                        >
                                            LOGOUT
                                        </button>
                                    </div>
                                </ul>
                            </div>
                        )}
                    </div> :
                        // <Link to="/login"><h1 className='text-md tracking-wider'>Login</h1></Link>
                        <div className='cursor-pointer'>
                            <div className="dropdown dropdown-hover">
                                <label id="0" className=" m-1 tracking-widest"><Link to={'/login'} className='flex gap-1 items-center text-md tracking-wider'> <span><RiUserSharedFill className='text-2xl' /></span> <span className='md:block hidden'>Login</span></Link></label>

                            </div>
                        </div>
                }
            </div>
        </div></div >
    );
};

export default Navbar;