import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../firebase.init';
import { useState } from 'react';
import { useEffect } from 'react';
import Navbar from '../layout/main/Navbar';

const MyProfile = () => {
    const [user] = useAuthState(auth);
    const [profile, setProfile] = useState([])
    const [companyProfile, setcompanyProfile] = useState([])
    useEffect(() => {
        const url = `https://api.rebzigo.com/user/${user?.email}`;
        fetch(url)
            .then(res => res.json())
            .then(data => setProfile(data))
    }, [user?.email])

    useEffect(() => {
        const url = 'https://api.rebzigo.com/couponCode';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const filterCompanyProfile = data.filter(item => item?.email === profile?.email)
                setcompanyProfile(filterCompanyProfile);
            })
    }, [companyProfile])
    return (
        <div>
            <Navbar />
            <div>
                <main class="profile-page">
                    <section class="relative py-16 bg-blueGray-200">
                        <div class="container mx-auto px-4">
                            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                                <div class="px-6">

                                    <div class="text-center mt-12">
                                        <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                            {profile?.name}
                                        </h3>
                                        <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                            <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                                            {profile?.email}
                                        </div>
                                        <div class="mb-2 text-blueGray-600 mt-1">
                                            <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i> Role - {profile?.role}
                                        </div>
                                        <div>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                                        {
                                            companyProfile && <div class="text-center my-2">
                                                <h1 className='text-center text-2xl font-semibold tracking-wider my-2'>Company Profile</h1>
                                                {
                                                    companyProfile.map(item => <div class="w-full lg:w-9/12 px-4">
                                                        <h1>Your Name -  <span>{item?.name}</span></h1>
                                                        <h1>Your Coupon Code -  <span>{item?.code}</span></h1>
                                                        <h1>Your Earning % - {item?.discount}%</h1>
                                                    </div>)
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>

        </div>
    );
};

export default MyProfile;