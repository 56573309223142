import { Controller, useForm } from 'react-hook-form';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import auth from '../firebase.init';
import useToken from '../hooks/useToken';
import Navbar from '../layout/main/Navbar';
import { useEffect } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';
    const [signInWithEmailAndPassword, user, , error] = useSignInWithEmailAndPassword(auth);
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const [token] = useToken(user);

    const onSubmit = async (data) => {
        const email = data.email;
        const password = data.password;
        if (email && password) {
            await signInWithEmailAndPassword(email, password);
            reset();
        }
    };

    useEffect(() => {
        if (error) {
            toast.error("Login failed. Please check your credentials.");
        }
    }, [error]);

    if (user) {
        navigate(from, { replace: true });
        toast.success('Login successful!');
        return null;
    }

    const resetPassword = async () => {
        if (user && user.email) {
            await sendPasswordResetEmail(auth, user.email);
            toast.success("Password reset email sent. Please check your email.");
        } else {
            toast.error('Please enter your email first.');
        }
    };
    return (
        <div className='h-screen'>
            <Navbar />
            <div className="flex items-center justify-center h-full py-16">
                <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="md:px-16 px-4 py-10">
                        <h2 className="text-3xl text-primary font-semibold text-center mb-10">
                            Please Login
                        </h2>
                        <div className="mt-4">
                            {errors.email && <p className='text-red-500'>{errors.email.message}</p>}
                            <h1>Email<span className='text-red-600'>*</span></h1>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <input
                                        className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                                        type="email"
                                        placeholder="Enter Your Email"
                                        {...field}
                                    />
                                )}
                            />

                        </div>

                        <div className="">
                            <h1 className='mt-3'>Password<span className='text-red-600'>*</span></h1>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters',
                                    },
                                }}
                                render={({ field }) => (
                                    <input
                                        type="password"
                                        className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                                        placeholder="Enter Your Password"
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        {errors.password && <p className='text-red-500'>{errors.password.message}</p>}
                        <p className='text-lg text-secondary mt-3 cursor-pointer flex items-center justify-between'>  <Link to="/passwordReset" className="text-[#7c3fb5e4] cursor-pointer"> Forgotten?</Link> <p className="ml-2  text-secondary text-lg">
                            <Link className="ml-2 underline text-purple-600 cursor-pointer" to="/register">
                                Registration
                            </Link>
                        </p></p>
                        <div className='flex justify-center'>
                            <input type="submit" value="Login" className="bg-primary text-md px-6 py-2 mt-4 hover:bg-transparent border-primary border hover:text-primary transition duration-300 rounded-lg text-white cursor-pointer " />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;