import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import auth from '../firebase.init';
import { toast } from 'react-toastify';
import verficationGif from "../Assets/verfication.gif"
const Verification = () => {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    useEffect(() => {
        const checkEmailVerification = async () => {
            if (user && !user.emailVerified) {
                try {
                    await user.sendEmailVerification();
                } catch (error) {
                }
            }
        };

        checkEmailVerification();

        const timer = setInterval(async () => {
            await user.reload();
            if (user.emailVerified) {
                clearInterval(timer);
                navigate('/login');
                toast.success("Your verification email has been verified")
            }
        }, 3000);

        return () => clearInterval(timer);
    }, [user, navigate]);

    return (
        <div className='h-screen flex justify-center flex-col items-center'>
            <img src={verficationGif} alt="" />
            <h1 className='text-xl font-semibold tracking-wider'>Email Verification Email Sent ..</h1>
            <p className='mt-2'>Please Check Your Email Address and Click The Link We Are Sent By Your email</p>
        </div>
    );
};

export default Verification;
