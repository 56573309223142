import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from 'swiper/modules';
import IntroductoryPage from '../ui/IntroductoryPage';
import { toast } from 'react-toastify';


const Banner = () => {
    const [banner, setBanner] = useState([]);
    const banners = [
        {
            img: "http://res.cloudinary.com/dzhlxua9i/image/upload/v1704112889/lbxpmcmesnctbhw14jag.jpg"
        },
        {
            img: "http://res.cloudinary.com/dzhlxua9i/image/upload/v1704112889/lbxpmcmesnctbhw14jag.jpg"
        },
        {
            img: "http://res.cloudinary.com/dzhlxua9i/image/upload/v1704112889/lbxpmcmesnctbhw14jag.jpg"
        }
    ]
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        const url = "https://api.rebzigo.com/banner";
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                setBanner(data);
                setLoading(false);
            } catch (error) {
                toast.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setBanner((prevBanner) => [...prevBanner].reverse());
        }, 30000);

        return () => clearInterval(interval);
    }, [banner]);
    return (
        <div className='bg-white'>  <Swiper
            centeredSlides={true}
            autoplay={{
                delay: 10000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
        >

            {
                loading ? <IntroductoryPage /> :
                    banner?.slice(0, 5).map(item => (<SwiperSlide key={item.id}>
                        <div className=''>
                            <img src={item?.img} alt="" className='h-48 md:h-[450px] w-full' />
                        </div>
                    </SwiperSlide>))

            }
        </Swiper></div>
    );
};

export default Banner;