import React from 'react';
import Navbar from '../../layout/main/Navbar';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../style.css'
import Loading from '../../shared/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { AddToquantity, removeOne } from '../../redux/features/Cart';
import { AddToCart } from '../../redux/features/AddToCart';
import { toast } from 'react-toastify';
import useProducts from '../../hooks/useProducts';
import Card from '../../components/ProductsCard/Card';
import ProductRequestModal from './ProductRequestModal';
import { TbCurrencyTaka } from 'react-icons/tb';
import { GoDotFill } from 'react-icons/go';
import Modal from 'react-modal';
import bkash from "../../Assets/bkash1.svg";
import Nogod from "../../Assets/nogod.png";
import cod from '../../Assets/cod-pay.png'
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import useNumber from '../../hooks/useNumber';
const ProductDetails = () => {
    const [confirmOrderModalOpen, setConfirmOrderModalOpen] = useState(false);
    const { id } = useParams()
    const [user] = useAuthState(auth)
    const [singleProduct, setSingleProduct] = useState(null)
    const [loading, setLoading] = useState(true);
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedMethod, setSelectedMethod] = useState('cod');
    const [showNumber] = useNumber()
    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        email: user?.email ? user.email : '',
        address: '',
        transactionId: '',
        upzila: '',
        district: '',
        phone2: ''
    });
    const handleImageClick = (method) => {
        setSelectedMethod(method);
    };
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        // Simulate fetching product data
        fetch(`https://api.rebzigo.com/products/${id}`)
            .then((res) => res.json())
            .then((json) => {
                setSingleProduct(json)
                setLoading(false)
            })
            .catch((error) => {
                setSingleProduct(null);
            });
    }, [id]);
    const [selectedImage, setSelectedImage] = useState(singleProduct?.topimg);
    useEffect(() => {
        setSelectedImage(singleProduct?.topimg);
    }, [singleProduct]);
    const handleImageClicks = (image) => {
        if (image === selectedImage) {
            setSelectedImage('');
        } else {
            setSelectedImage(image);
        }
    };
    const [hookProducts] = useProducts()
    const allImages = [];
    if (singleProduct && singleProduct.topimg) {
        allImages.push(singleProduct.topimg);
    }
    if (singleProduct && singleProduct.img) {
        allImages.push(...singleProduct.img);
    }
    const [isProductRequestModalOpen, setIsProductRequestModalOpen] = useState(false);
    // Handling Cart 
    const cartItem = useSelector(state => state?.cart)
    const dispatch = useDispatch()
    const handleAddToQuantity = () => {
        dispatch(AddToquantity(singleProduct));
        singleProduct.quantity = (singleProduct?.quantity || 1) + 1;
    };

    const handleRemoveFromCart = () => {
        dispatch(removeOne(singleProduct));
        if (cartItem) {
            singleProduct.quantity = (singleProduct?.quantity || 1) - 1;
        }
    };
    const handleAddToCart = (singleProduct, quantity) => {
        if (!selectedSize) {
            toast.error('Please select a size before adding to cart.');
            return;
        }
        if (singleProduct?.stock < (singleProduct?.quantity || 1)) {
            toast.error('Sorry, this product Stock is Limited.');
            return;
        }
        toast.success('Cart added successfully');
        const payload = { product: { ...singleProduct, size: selectedSize, quantity } };
        dispatch(AddToCart(payload));
    };

    if (loading) {
        return <Loading />;
    }
    const deliveryCharge = 60
    const date = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).replace(/,/g, '');
    const handleProductRequest = (formData) => {
        fetch('https://api.rebzigo.com/reqestProducts', {
            method: 'POST', // Use POST method
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Convert formData to JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                toast.success("Request sent successfully")
            })
    };
    const handleSizeClick = (size) => {
        setSelectedSize(size);
    };
    const productQuantity = singleProduct.quantity === undefined | singleProduct.quantity === 0 ? 1 : singleProduct.quantity
    const allProducts = [];
    hookProducts?.forEach((product) => {
        if (product?.category === singleProduct?.category && product?.id !== singleProduct?.id) {
            allProducts?.push({ ...product, type: 'similar' });
        } else {
            allProducts?.push({ ...product, type: 'other' });
        }
    });
    const hasSimilarProducts = allProducts?.some((product) => product?.type === 'similar');
    allProducts?.sort((a, b) => {
        if (a?.type === 'similar' && b?.type === 'other') {
            return -1;
        } else if (a?.type === 'other' && b?.type === 'similar') {
            return 1;
        }
        return 0;
    });
    if (loading) {
        return <Loading />;
    }
    const handleSubmitform = (e) => {
        e.preventDefault();
        // Phone Number Regex Verify 
        const phonePattern = /^01[0-9]{9}$/;
        if (!phonePattern.test(formData.phone)) {
            toast.error('Invalid phone number. Please use the correct format: +8801756XXXXXX');
            return;
        }
        const orderedProducts = [{
            _id: singleProduct?._id,
            name: singleProduct?.productName,
            quantity: productQuantity,
            pprice: singleProduct.purchasePrice,
            img: singleProduct?.topimg,
            code: singleProduct?.productCode,
            size: selectedSize,
            sellPrice: singleProduct?.sellPrice
        }]
        const status = "Pending"
        const sell = 'totalSell'
        const benifit = 'totalBenifit'
        const order = 'totalOrder'
        const postData = {
            ...formData,
            paymentMethod: selectedMethod,
            orderedProducts,
            discountedTotal: parseFloat(singleProduct.sellPrice) * parseFloat(productQuantity) + parseFloat(deliveryCharge),
            status,
            total: parseFloat(singleProduct.sellPrice) * parseFloat(productQuantity),
            purchesTotal: parseFloat(singleProduct.purchasePrice) * parseFloat(productQuantity),
            date,
            sell,
            benifit,
            order
        };
        fetch('https://api.rebzigo.com/orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const updatedStock = parseInt(singleProduct.stock) - parseInt(productQuantity);
                fetch(`https://api.rebzigo.com/products/${singleProduct._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ stock: updatedStock }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Failed to update product stock');
                        }
                    })
                toast.success('Order submitted successfully. Check your Order Page.');
                setConfirmOrderModalOpen(false)
            })
            .catch((error) => {
                toast.error("Order couldn't be submitted");
            });
    };
    return (
        <div>
            <Navbar />
            <section class="">
                <div class="w-11/12  py-4 mx-auto lg:py-8 md:px-6">
                    <div class="flex flex-wrap  justify-center">
                        <div class="w-full md:w-1/2 mb-4 md:mb-0 md:-mr-2">
                            <div class="sticky top-0 overflow-hidden ">
                                <div class="w-full">
                                    <img src={selectedImage || allImages?.[0]} alt=""
                                        class="md:w-[95%] bg-white w-full md:mx-auto" />
                                </div>
                                <div class="flex-wrap flex">
                                    <div class="md:w-[95%] w-full md:mx-auto bg-black">
                                        <div
                                            class="bg-black px-3">
                                            {
                                                <div className='flex items-center justify-evenly gap-5 bg-black w-full h-20'>
                                                    <div>

                                                        {singleProduct?.stock == 0 | singleProduct?.stock === null
                                                            ?
                                                            <del className='flex  line-through  text-2xl items-center text-white'><TbCurrencyTaka /> <span>{singleProduct?.sellPrice}</span></del> : <h1 className='flex text-white text-2xl items-center'><TbCurrencyTaka /><span>{singleProduct?.sellPrice}</span></h1>}
                                                    </div>
                                                    <div className='flex gap-4 w-full justify-end'>
                                                        {allImages?.map((item, index) => (
                                                            <img src={item} alt=""
                                                                key={index}
                                                                className="object-cover w-16 lg:h-16 inl"
                                                                onClick={() => handleImageClicks(item)} />
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="px-1 md:w-1/2 bg-white pt-2 md:mx-1 w-full">
                            <div class="lg:ml-2">
                                <div class="mb-2 ">
                                    <h2 class="mt-2 mb-2 md:text-4xl text-xl font-bold">
                                        {singleProduct?.productName}</h2>
                                </div>
                                <div class="flex items-center">
                                    <div class="flex items-center">
                                        <div class="w-full lg:mb-0">
                                            {singleProduct?.size?.length === 0 ? (
                                                ''
                                            ) : (
                                                <div class="w-full mb-2">
                                                    <div class="relative flex flex-row w-full h-10 mt-4 bg-transparent rounded-lg">
                                                        {singleProduct?.size?.map((size) => (
                                                            <button
                                                                onClick={() => handleSizeClick(size)} // Step 2
                                                                class={`py-1 mb-2 mr-1 border w-full px-4 hover:border-blue-400 dark:border-gray-400 hover:text-blue-600 dark:hover:border-gray-300 text-primary ${selectedSize === size ? 'bg-gray-400' : '' // Step 3
                                                                    }`}
                                                            >
                                                                {size}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-4 w-full'>
                                    <div class="w-32">
                                        <div class="relative flex flex-row w-full h-10 mt-1 bg-transparent rounded-lg">
                                            <button onClick={handleAddToQuantity}
                                                class="w-20 h-full bg-gray-300 rounded-l outline-none cursor-pointer  hover:text-gray-700  hover:bg-gray-400">
                                                <span class="m-auto text-2xl font-thin">+</span>
                                            </button>
                                            <input type="pasword"
                                                class="flex items-center w-full font-semibold text-center text-primary placeholder-primary  outline-none  focus:outline-none text-md bg-gray-400"
                                                value={singleProduct?.quantity || 1} />
                                            <button onClick={handleRemoveFromCart}
                                                class="w-20 h-full text-primary bg-gray-300 rounded-r outline-none cursor-pointer hover:text-gray-700 hover:bg-gray-400">
                                                <span class="m-auto text-2xl font-thin">-</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="md:flex flex-wrap items-center -mx-4 mt-1 w-full">
                                        {singleProduct?.stock == 0 | singleProduct?.stock === null
                                            ? <div class="w-full px-4 mb-4 lg:w-1/2 lg:mb-0 mx-auto">

                                                <button onClick={() => {
                                                    if (!selectedSize) {
                                                        toast.error('Please select a size before confirming the order.');
                                                        return;
                                                    }
                                                    setIsProductRequestModalOpen(true);
                                                }}

                                                    class="flex items-center justify-center w-full p-2  text-white bg-rose-500 border  rounded-md">
                                                    Please Need It
                                                </button>
                                                <ProductRequestModal
                                                    isOpen={isProductRequestModalOpen}
                                                    onRequestClose={() => setIsProductRequestModalOpen(false)}
                                                    selectedSize={selectedSize}
                                                    productsData={singleProduct}
                                                    handleProductRequest={handleProductRequest}
                                                />
                                            </div> : <div className='flex justify-between items-center w-full'><div class="md:w-[90%] w-[85%]  mb-4 px-1 lg:mb-0 ">
                                                <button onClick={() => handleAddToCart(singleProduct, singleProduct?.quantity ? singleProduct.quantity : 1)}

                                                    class="flex items-center justify-center w-full py-[10px] bg-primary text-white border-primary hover:bg-transparent hover:text-primary transition duration-300 border rounded-md md:text-[14px] text-[12px]">
                                                    Add to Cart
                                                </button>
                                            </div> <div class="w-full px-4 mb-4 lg:w-1/2 hidden lg:mb-0">
                                                    <button onClick={() => {
                                                        if (!selectedSize) {
                                                            toast.error('Please select a size before confirming the order.');
                                                            return;
                                                        }
                                                        setConfirmOrderModalOpen(true);
                                                    }}

                                                        class="flex items-center justify-center w-full py-[10px] bg-primary text-white border-primary  hover:bg-transparent hover:text-primary transition duration-300 border md:text-[14px] text-[12px] rounded-md">
                                                        Order Now
                                                    </button>
                                                </div></div>
                                        }
                                    </div>
                                </div>
                                <p className='mt-2 font-semibold'>Description:</p>
                                <div className="mt-1" style={{ columnCount: 1, overflow: 'hidden' }}>
                                    {singleProduct?.description && (
                                        <ul className='list-disc'>
                                            {singleProduct.description.split('/n').map((line, index) => (
                                                <li className='flex items-center gap-2' key={index}> <GoDotFill />{line}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='w-10/12 mx-auto'>
                <hr />
            </div>
            <div className="my-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 w-11/12 mx-auto gap-2">
                {hasSimilarProducts ? (
                    allProducts?.slice(0, 36)?.map((product) => (
                        <Card key={product.id} product={product}></Card>
                    ))
                ) : (
                    hookProducts?.slice(0, 36)?.map((product) => (
                        <Card key={product.id} product={product}></Card>
                    ))
                )}
            </div>
            <div className='w-full'>
                <Modal
                    isOpen={confirmOrderModalOpen}
                    onRequestClose={() => setConfirmOrderModalOpen(false)}
                    contentLabel="Confirm Order Modal"
                >
                    <div className="md:p-4">
                        <div className="flex justify-end">
                            {/* Close button */}
                            <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={() => setConfirmOrderModalOpen(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <h2 className="text-2xl font-bold mb-4">Confirm Order</h2>
                        <form onSubmit={handleSubmitform}>
                            <div class="">
                                <div className='flex gap-5'>
                                    <div>
                                        <label for="fullName" class="mt-4 mb-2 block text-sm font-medium">Full Name</label>
                                        <div class="relative">
                                            <input type="text" id="fullName" name="fullName" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize  shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Your full name here"
                                                onChange={handleInputChange}
                                                required value={formData?.fullName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label for="phone" class="mt-4 mb-2 block text-sm font-medium">Phone</label>
                                        <div class="relative">
                                            <input type="number" id="phone" name="phone" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="0182****25"
                                                required
                                                onChange={handleInputChange}
                                                pattern="^\01[0-9]{9}$"
                                                value={formData?.phone}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <label for="phone2" class="mt-4 mb-2 block text-sm font-medium">Phone 2</label>
                                <div class="relative">
                                    <input type="number" id="phone2" name="phone2" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="0182****25"
                                        onChange={handleInputChange}
                                        required
                                        value={formData?.phone2}

                                    />
                                </div>
                                <label htmlFor="email" className="mt-4 mb-2 block text-sm font-medium">
                                    Email
                                </label>
                                <div className="relative">
                                    {user ? (
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                                            placeholder="Enter your email address"
                                            onChange={handleInputChange}
                                            required
                                            value={user?.email}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                                            placeholder="Enter your email address"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    )}
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div>
                                        <label for="District" class="mt-4 mb-2 block text-sm font-medium">District</label>
                                        <div class="relative">
                                            <input type="text" id="district" name="district" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Dhaka"
                                                onChange={handleInputChange} required
                                                value={formData?.district}

                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label for="upzila" class="mt-4 mb-2 block text-sm font-medium">UpZila</label>
                                        <div class="relative">
                                            <input type="text" id="upzila" name="upzila" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Mirpur"
                                                onChange={handleInputChange}
                                                required value={formData?.upzila}

                                            ></input></div>
                                    </div>
                                </div>
                                <div>
                                    <label for="address" class="mt-4 mb-2 block text-sm font-medium">Address</label>
                                    <div class="relative">
                                        <input type="text" id="address" name="address" class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Detailed Address"
                                            onChange={handleInputChange}
                                            required value={formData?.address}
                                        />
                                    </div>
                                </div>
                                <h2 className='flex items-center justify-between py-2'>Sub Total - <span>{parseFloat(singleProduct?.sellPrice) * parseFloat(productQuantity)} bdt</span></h2>
                                <h1 className='flex items-center justify-between py-2'>Delivery Charge Inside Bangladesh - <span>{deliveryCharge} bdt</span></h1>
                                <h1 className='border-[1px] border-black'> </h1>
                                <h1 className='py-2 flex items-center justify-between'>Total - <span>{parseFloat(singleProduct?.sellPrice) * parseFloat(productQuantity) + parseFloat(deliveryCharge)} bdt</span></h1>

                                {/* Payment Method  */}
                                <p class="my-2 text-lg font-medium">Payment Methods</p>
                                <div className='flex items-center gap-6 mt-5'>
                                    <div className='relative'>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            id="cod"
                                            className='absolute bottom-8 left-2'
                                            checked={selectedMethod === 'cod'}
                                            onChange={() => handleImageClick('cod')}
                                        />
                                        <label htmlFor="cod" onClick={() => handleImageClick('cod')}>
                                            <img className='w-32 bg-white shadow-md h-16 md:px-8 md:py-4' src={cod} alt="" />
                                        </label>
                                    </div>
                                    <div className='relative'>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            id="nogod"
                                            className='absolute bottom-8 left-2'
                                            checked={selectedMethod === 'nogod'}
                                            onChange={() => handleImageClick('nogod')}
                                        />
                                        <label htmlFor="nogod" onClick={() => handleImageClick('nogod')}>
                                            <img className='w-32 bg-white shadow-md h-16 md:px-8 md:py-4' src={Nogod} alt="" />
                                        </label>
                                    </div>
                                    <div className='relative'>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            id="bkash"
                                            className='absolute bottom-8 left-2'
                                            checked={selectedMethod === 'bkash'}
                                            onChange={() => handleImageClick('bkash')}
                                        />
                                        <label htmlFor="bkash" onClick={() => handleImageClick('bkash')}>
                                            <img className='w-32 bg-white shadow-md h-16 md:px-8 md:py-4' src={bkash} alt="" />
                                        </label>
                                    </div>
                                </div>
                                {
                                    selectedMethod !== 'cod' && <div className='my-4 tracking-widest text-sm flex gap-4 items-center'>
                                        {
                                            showNumber?.map(item => <div>
                                                <h1 className='text-pink-500 font-bold'><span className=' text-orange-500'>{item?.method}</span> - {item?.number}</h1>
                                            </div>)
                                        }
                                    </div>
                                }
                                {
                                    selectedMethod !== 'cod' && <>
                                        <label for="transaction-id" class="mt-4 mb-2 block text-sm font-medium">Transition ID</label>
                                        <div class="relative">
                                            <input type="text" id="transaction-id"
                                                name="transactionId" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Your Transition ID"
                                                required={selectedMethod !== 'cod'}
                                                onChange={handleInputChange} />
                                        </div>
                                    </>
                                }
                            </div>
                            <button type="submit" className="mt-4 bg-primary text-white px-4 py-2 rounded-md">
                                Confirm Order
                            </button>
                        </form>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ProductDetails;