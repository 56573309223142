import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CouponCode = () => {
    const [couponCode, setCouponCode] = useState([])
    useEffect(() => {
        const url = 'https://api.rebzigo.com/couponCode';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setCouponCode(data)
            })
    }, [])
    const handleDelete = (id) => {
        const proced = window.confirm("Are Your Sure Delete This User");
        if (proced) {
            const url = `https://api.rebzigo.com/couponCode/${id}`;

            fetch(url, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {

                    const reamingData = couponCode.filter((user) => user._id !== id);
                    setCouponCode(reamingData);
                    toast.success("Succesfully Delete Coupon Code");
                });
        }
    };

    const navigate = useNavigate()

    const handleUpdateCode = (id) => {
        navigate(`/dashboard/couponCode/${id}`)
    }

    return (
        <section className="my-4">
            <h1 className="text-2xl font-bold text-center mb-4">Coupon Code Managment</h1>
            <div className="flex items-center w-10/12 mx-auto justify-between my-4">
                <Link to="/dashboard/AddCouponCode" className="bg-primary text-white tracking-wider border border-primary hover:bg-transparent hover:text-primary transition duration-300 px-4 py-2 cursor-pointer">Add Coupon Code</Link>
            </div>
            <div className="w-11/12 mx-auto">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono ">
                                        <th
                                            className="
                                 w-1/6
                                 min-w-[160px]
                                 text-lg
                                 font-semibold
                                 text-secondary
                                 py-4
                                 lg:py-4
                                 px-3
                                 lg:px-4
                                 border-l border-transparent
                                 font-mono
                                 "
                                        >
                                            Code Name
                                        </th>
                                        <th
                                            className="
                                 w-1/6
                                 min-w-[160px]
                                 text-lg
                                 font-semibold
                                 text-secondary
                                 py-4
                                 lg:py-4
                                 px-3
                                 lg:px-4
                                 font-mono
                                 "
                                        >
                                            Email
                                        </th>
                                        <th
                                            className="
                                 w-1/6
                                 min-w-[160px]
                                 text-lg
                                 font-semibold
                                 text-secondary
                                 py-4
                                 lg:py-4
                                 px-3
                                 lg:px-4
                                 "
                                        >
                                            Name
                                        </th>
                                        <th
                                            className="
                                 w-1/6
                                 min-w-[160px]
                                 text-lg
                                 font-semibold
                                 text-secondary
                                 py-4
                                 lg:py-4
                                 px-3
                                 lg:px-4
                                 "
                                        >
                                            Discount
                                        </th>
                                        <th
                                            className="
                                 w-1/6
                                 min-w-[160px]
                                 text-lg
                                 font-semibold
                                 text-secondary
                                 py-4
                                 lg:py-4
                                 px-3
                                 lg:px-4
                                 "
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {couponCode?.map((item) => (
                                    <tbody>
                                        <tr className="font-mono">
                                            <td
                                                className="
                                 text-center text-dark
                                 font-medium
                                 text-secondary
                                 py-5
                                 px-2 bg-transparent
                                 border-b border-l border-primary
                                 "
                                            >
                                                {item?.code}
                                            </td>
                                            <td
                                                className="
                                 text-center text-dark
                                 font-medium
                                 text-secondary
                                 py-5
                                 px-2 bg-transparent
                                 border-b border-primary
                                 "
                                            >
                                                {item?.email}
                                            </td>
                                            <td
                                                className="
                                 text-center text-dark
                                 font-medium
                                 text-secondary
                                 py-5
                                 px-2 bg-transparent
                                 border-b border-primary
                                 "
                                            >
                                                {item?.name}
                                            </td>
                                            <td
                                                className="
                                 text-center text-dark
                                 font-medium
                                 text-secondary
                                 py-5
                                 px-2 bg-transparent
                                 border-b border-primary
                                 "
                                            >
                                                {item?.discount}%
                                            </td>
                                            <td
                                                className="
                                              text-center text-dark
                                              font-medium
                                              text-secondary
                                              py-5
                                              px-2 bg-transparent
                                              border-b border-primary
                                              "
                                            >
                                                <div className=" cursor-pointer justify-center flex items-center gap-2">
                                                    <h1 onClick={() => handleDelete(item?._id)}
                                                        className="border-r border-primary px-2 text-red-500">Delete</h1>
                                                    <h1 onClick={() => handleUpdateCode(item?._id)}>Edit</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default CouponCode;