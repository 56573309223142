import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TbCurrencyTaka } from "react-icons/tb";
const Card = ({ product }) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location.pathname]);
    return (
        <Link to={`/products/${product?._id}`} className='rounded-sm mx-auto bg-white cursor-pointer relative mb-4 hover:border hover:border-primary transition-all duration-300'>
            <div>
                <img src={product?.topimg} className='w-48  h-[173px] md:w-60 md:h-52 mx-auto  rounded-sm' alt="" />
            </div>
            <div className='px-3 w-full bg-[#000] text-white  mt-5 py-2    bg'>
                {/* <h1 className='text-sm'>{product?.productName.slice(0, 23)}...</h1> */}
                <div className='flex justify-between items-center'>
                    <h1 className='flex items-center'> <TbCurrencyTaka /> {product?.sellPrice}</h1>

                    <h1>{product?.stock == 0 | product?.stock === null ? 'Request' : "Sell"}</h1>
                </div>

            </div>
        </Link>
    );
};

export default Card;
