import { BsSearch } from 'react-icons/bs';
import { setSearchFilter, resetSearchFilter } from '../../redux/features/SearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const dispatch = useDispatch();
    const searchFilter = useSelector((state) => state.search);
    const handleSearchChange = (event) => {
        const value = event.target.value;
        dispatch(setSearchFilter(value));
    };
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    const navigateToProduct = (productId) => {
        navigate(`/products/${productId}`);
        dispatch(resetSearchFilter()); // Reset the search filter when navigating
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigate(`/categories`);
        }
    };

    useEffect(() => {
        const url = "https://api.rebzigo.com/product";
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                if (searchFilter) {
                    const filteredProducts = data.filter((product) =>
                        Object.values(product)
                            .some((value) =>
                                typeof value === 'string' && value.toLowerCase().includes(searchFilter.toLowerCase())
                            )
                    );
                    setSuggestions(filteredProducts);
                } else {
                    setSuggestions([]);
                }
            });
    }, [searchFilter]);

    useEffect(() => {
        const closeSuggestionsOnOutsideClick = () => {
            setSuggestions([]);
        };

        document.addEventListener('click', closeSuggestionsOnOutsideClick);
        return () => {
            document.removeEventListener('click', closeSuggestionsOnOutsideClick);
        };
    }, []);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div className='relative'>
            <div className="relative overflow-hidden md:w-full w-56 p-0 mx-auto  rounded-sm -ml-8 md:ml-0">
                <input
                    type="text"
                    className="placeholder-secondary border-none px-6 pl-8 md:text-sm text-[10px] font-mono input md:py-4 py-4 w-full rounded-lg text-secondary bg-[#c6c7c8] md:h-full h-6"
                    placeholder="Search by Products ..."
                    value={searchFilter}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress} onClick={(e) => e.stopPropagation()}
                />
                <h1 className='absolute top-1/3 left-4 text-primary'><BsSearch /></h1>
            </div>
            <div className='absolute top-12 bg-[#c6c7c8] shadow-xl text-primary z-10 px-10'>
                {suggestions.length > 0 && searchFilter && (
                    <div className="suggestions md:py-2 cursor-pointer" onClick={stopPropagation}>
                        <ul>
                            {suggestions.slice(0, 8).map((suggestion) =>
                                <li
                                    onClick={() => navigateToProduct(suggestion?._id)}
                                    className='py-1'
                                    key={suggestion._id}
                                >
                                    {suggestion.productName}
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchBar;