import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/apiSlice';
import SearchSlice from './features/SearchSlice';
import CategorySlice from './features/CategorySlice';
import Cart from './features/Cart';
import AddToCart from './features/AddToCart';
;

const store = configureStore({
    reducer: {
        search: SearchSlice,
        categories: CategorySlice,
        cart: Cart,
        AddToCart: AddToCart,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});


export default store;
