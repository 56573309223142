import React from 'react';
import { useParams } from 'react-router-dom'; // Add this import
import { useForm, Controller } from 'react-hook-form';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import Navbar from '../layout/main/Navbar';
import auth from '../firebase.init';

const PasswordReset = () => {
    const [sendPasswordResetEmail, sending] = useSendPasswordResetEmail(auth);
    const { control, handleSubmit, formState: { errors } } = useForm();

    // Add the following line to get the email from the URL parameters
    const { email } = useParams();

    const onSubmit = async (data) => {
        // Use the email from the URL parameters, or the user input if provided
        const userEmail = data.email || email;

        if (userEmail) {
            try {
                await sendPasswordResetEmail(userEmail);
                toast.success("Please check your email for a password reset link.");
            } catch (error) {
                toast.error("Password reset email could not be sent. Please try again.");
            }
        } else {
            toast.error('Please enter your email or use the provided link.');
        }
    };

    return (
        <div>
            <Navbar />
            <div className='flex items-center justify-center h-full my-20'>
                <div>
                    <h2 className="text-2xl text-primary font-semibold text-center mb-10">
                        Reset Your Password
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-4">
                            {errors.email && <p className='text-red-500'>{errors.email.message}</p>}
                            <h1>Email<span className='text-red-600'>*</span></h1>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <input
                                        className='border border-primary px-4 py-3 rounded-lg placeholder:text-primary w-72 mt-3'
                                        type="email"
                                        placeholder="Enter Your Email"
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <input type="submit" value="Reset Password" className="bg-primary text-md px-10 py-3 mt-4 hover:bg-transparent border-primary border hover:text-primary transition duration-300 rounded-lg text-white" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
