import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const cloudinaryCloudName = 'dzhlxua9i';
const cloudinaryTopImgUploadPreset = 'ecommerce_product_images';

const AddDiscountImg = () => {
    const navigate = useNavigate()
    const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).replace(/,/g, '');

    const [formData, setFormData] = useState({
        createdAt: currentDate,
        img: null,
    });


    const uploadImageToCloudinary = async (image, cloudinaryUploadPreset) => {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('upload_preset', cloudinaryUploadPreset);
        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                console.error('Cloudinary Response:', response);
                throw new Error('Image upload to Cloudinary failed');
            }
            const data = await response.json();
            return data?.url;
        } catch (error) {
            console.error('Error uploading image to Cloudinary:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Upload top image and get the URL
        let topImageUrl = '';
        if (formData?.img) {
            try {
                topImageUrl = await uploadImageToCloudinary(formData?.img, cloudinaryTopImgUploadPreset);
            } catch (error) {
                toast.error('Error uploading top image');
                return;
            }
        }

        // Prepare the data to be sent to the server
        const dataToSend = {
            ...formData,
            img: topImageUrl, // Replace top image with URL
        };

        fetch('https://api.rebzigo.com/discount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                toast.success('Discount Image Post successfully');
                navigate("/dashboard/showDiscountImg")
            })
            .catch((error) => {
                console.error('Error sending data:', error);
                toast.error('Error sending data');
            });
    };

    // Handle single image upload
    const handleSingleImageUpload = (e) => {
        const { name, files } = e?.target;
        setFormData({
            ...formData,
            [name]: files[0], // Store the selected file
        });
    };

    return (
        <div className="p-4 bg-gray-100">
            <form onSubmit={handleSubmit} className=" w-11/12 mx-auto bg-white p-4 shadow-md rounded-lg">
                <h2 className="text-2xl font-semibold mb-4">Add a New Discount Image</h2>
                <div className="mt-4">
                    <label htmlFor="img" className="block text-gray-600 font-semibold mb-2">Image Upload</label>
                    <input
                        type="file"
                        id="img"
                        name="img"
                        onChange={handleSingleImageUpload}
                        className="w-full border border-gray-300 rounded-lg p-2"
                    />
                </div>

                <button type="submit" className="w-full bg-blue-500 text-white font-semibold rounded-lg p-2 hover:bg-blue-600 mt-4">
                    Add Discount Image
                </button>
            </form>
        </div>
    );
};

export default AddDiscountImg;
