import React, { useEffect, useState } from 'react';
import Card from '../../components/ProductsCard/Card';
import Loading from '../../shared/Loading';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const loadProducts = (page = 1) => {
        const url = `https://api.rebzigo.com/products?page=${page}`;
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setProducts(data.products);
                setTotalPages(data.totalPages);
                setLoading(false);
            });
    };

    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setCurrentPage(page);
            loadProducts(page);
        }
    };
    const reverse = [...products].reverse();
    useEffect(() => {
        loadProducts(currentPage);
        const interval = setInterval(() => {
            loadProducts(currentPage);
        }, 30000);
        return () => clearInterval(interval);
    }, [currentPage]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className='md:py-10 py-4 -z-10'>
            <div className='w-[98%] md:w-11/12 lg:w-11/12 mx-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 gap-2'>
                {reverse?.map(product => (
                    <Card key={product.id} product={product}></Card>
                ))}
            </div>
            <div className="mt-4 flex justify-center">
                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`mx-2 px-4 py-2 rounded ${page === currentPage ? 'bg-black text-white cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                        disabled={page === currentPage}
                    >
                        {page}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Products;
