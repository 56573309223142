// MessengerChat.js

import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
const MessengerChat = () => {
  return (
    <MessengerCustomerChat
      pageId="141445609052468"
      appId="768060778236660"
    />
  )
};

export default MessengerChat;
