import React from 'react';
import Navbar from '../../layout/main/Navbar';
import Banner from '../../components/Home/Banner';
import Categories from '../../components/Home/Categories';
import Products from '../Products/Products';
import Footer from '../../shared/Footer';
import TopupAds from '../../shared/TopUpAds/TopupAds';
import Discount from '../Discount';
const Home = () => {
    return (
        <div>
            <Navbar />
            <Banner />
            <TopupAds />
            <Categories />
            <Products />
            <Discount />
            <Footer />
        </div>
    );
};

export default Home;