import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import auth from '../../../../firebase.init';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const CouponCodeAdd = () => {
    const { register, handleSubmit } = useForm();
    const [user] = useAuthState(auth);

    const navigate = useNavigate()
    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://api.rebzigo.com/couponCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data?.email,
                    code: data.code,
                    discount: data.discount,
                    name: data.name
                }),
            });
            if (response.ok) {
                toast.success('Coupon Code added successfully');
                navigate(`/dashboard/couponCode`);
            } else {
                toast.error('Failed to add Coupon Code');
            }
        } catch (error) {
            toast.error('Error adding Coupon Code:', error);
        }
    };

    return (
        <div>
            <h1 className='mt-10 text-center text-2xl font-semibold tracking-wider'>Add a Coupon Code</h1>
            <form onSubmit={handleSubmit(onSubmit)} className='w-10/12 mx-auto mt-4'>
                <div>
                    <label htmlFor='email' className="font-mono text-secondary">Employee Email</label>
                    <input
                        id='email'
                        type="text"
                        placeholder="Enter Employee email.."
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                        {...register("email")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='name' className="mt-3 font-mono text-secondary">Add Employee Name</label>
                    <input
                        id='name'
                        type="text"
                        placeholder="Enter employee name"
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                        {...register("name")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='code' className="font-mono text-secondary mt-3">Coupon Code Name</label>
                    <input
                        id='code'
                        type="text"
                        placeholder="Add Coupon Code Name"
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                        {...register("code")}
                        required
                    />
                </div>
                <div>
                    <label htmlFor='discount' className="mt-3 font-mono text-secondary">Add Discount</label>
                    <input
                        id='discount'
                        type="number"
                        placeholder="Enter Discount Number "
                        className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                        {...register("discount")}
                        required
                    />
                </div>
                <input type="submit" value="Submit" className='text-md bg-primary text-white border border-primary hover:bg-transparent hover:text-primary rounded-md transition duration-300 w-full py-4 mt-4 cursor-pointer' />
            </form>
        </div>
    );
};

export default CouponCodeAdd;