import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const UpdateCategory = () => {
    const { id } = useParams();
    const [categoryData, setCategoryData] = useState({ email: '', category: '', subCategory: '', });

    useEffect(() => {
        // Fetch the category data based on the ID
        fetch(`https://api.rebzigo.com/category/${id}`)
            .then((res) => res.json())
            .then((data) => {
                setCategoryData(data);
            })
            .catch((error) => {
                console.error('Error fetching category data:', error);
            });
    }, [id]);

    const navigate = useNavigate()

    const handleUpdate = () => {
        // Send a PUT request to update the category
        fetch(`https://api.rebzigo.com/category/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryData),
        })
            .then((res) => {
                if (res.ok) {
                    toast.success('Category updated successfully');
                    navigate("/dashboard/AllCategories")
                } else {
                    console.error('Failed to update category');
                }
            })
            .catch((error) => {
                console.error('Error updating category:', error);
            });
    };

    const handleInputChange = (e) => {
        setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <h1 className='mt-4 text-2xl font-semibold text-center tracking-wider'>Update Category</h1>
            <div className='flex flex-col w-10/12 mx-auto mt-3'>
                <input
                    type="text"
                    name="email"
                    className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                    value={categoryData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                />
                <input
                    type="text"
                    name="category"
                    className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                    value={categoryData.category}
                    onChange={handleInputChange}
                    placeholder="Category Name"
                />
                <input
                    type="text"
                    name="subCategory"
                    className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                    value={categoryData.subCategory}
                    onChange={handleInputChange}
                    placeholder="Sub Category"
                />
                <button className='text-md bg-primary text-white border border-primary hover:bg-transparent hover:text-primary rounded-md transition duration-300 w-full py-4 mt-4 cursor-pointer' onClick={handleUpdate}>Update Category</button>
            </div >
        </div>
    );
};

export default UpdateCategory;
