import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import ProductDetails from "../pages/Products/ProductDetails";
import Products from "../pages/Products/Products";
import Carts from "../pages/Carts";
import Checkout from "../pages/Orders/Checkout";
import Slider from "../pages/Dashboard/Slider/Slider"
import Categories from "../pages/Categories";
import Login from "../Account/Login";
import Registation from "../Account/Registation";
import PasswordReset from "../Account/PasswordReset";
import Verification from "../Account/Verification";
import UpdateProducts from "../pages/Dashboard/Admin/Products/UpdateProducts";
import AddCategory from "../pages/Dashboard/Admin/Category/AddCategory";
import User from "../pages/Dashboard/Admin/Users/User";
import AllProducts from "../pages/Dashboard/Admin/Products/AllProducts";
import AddProducts from "../pages/Dashboard/Admin/Products/AddProducts";
import AllCategories from "../pages/Dashboard/Admin/Category/AllCategories";
import UpdateCategory from "../pages/Dashboard/Admin/Category/UpdateCategory";
import CouponCode from "../pages/Dashboard/Admin/CouponCode/CouponCode";
import CouponCodeAdd from "../pages/Dashboard/Admin/CouponCode/CouponCodeAdd";
import CouponCodeEdit from "../pages/Dashboard/Admin/CouponCode/CouponCodeEdit";
import MyOrders from "../pages/Orders/MyOrders";
import NewProducts from "../pages/Products/NewProducts";
import OfferProducts from "../pages/Products/OfferProducts";
import ReqestProducts from "../pages/Dashboard/Admin/ReqestProducts";
import OrderDetails from "../pages/Dashboard/Admin/OrderDetails";
import MyProfile from "../shared/MyProfile";
import Blogs from "../pages/Dashboard/Admin/Blogs/Blogs";
import BlogsAdd from "../pages/Dashboard/Admin/Blogs/BlogsAdd";
import BlogsData from "../pages/Blogs/BlogsData";
import Error from "../shared/Error";
import About from "../pages/Home/About";
import Employee from "../pages/Dashboard/Admin/Employee/Employee";
import AddSize from "../pages/Dashboard/Admin/Products/AddSize";
import AddNews from "../pages/Dashboard/Admin/AddNews";
import AddNumber from "../pages/Dashboard/Admin/PhNumber/AddNumber";
import ShowBanner from "../pages/Dashboard/Admin/Banner/ShowBanner";
import AddBanner from "../pages/Dashboard/Admin/Banner/AddBanner";
import ShowNumber from "../pages/Dashboard/Admin/PhNumber/ShowNumber";
import AdminDashboard from "../pages/Dashboard/Admin/AdminDashboard";
import AddDiscountImg from "../pages/Dashboard/Admin/DiscountImg/AddDiscountImg";
import DiscountImage from "../pages/Dashboard/Admin/DiscountImg/DiscountImage";
import PaidOrder from "../pages/Dashboard/Admin/PaidOrder";
import CompleteOrder from "../pages/Dashboard/Admin/CompleteOrder";
import CompleteOrderDetails from "../pages/Dashboard/Admin/CompleteOrderDetails";
import PendingOrder from "../pages/Dashboard/Admin/PendingOrder";
import AllSize from "../pages/Dashboard/Admin/AllSize";
const routes = createBrowserRouter([
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "register",
        element: <Registation />,
    },
    {
        path: "passwordReset",
        element: <PasswordReset />,
    },
    {
        path: "verification",
        element: <Verification />,
    },
    {
        path: "products",
        element: <Products />,
    },
    {
        path: "products/:id",
        element: <ProductDetails />,
    },
    {
        path: "cart",
        element: <Carts />,
    },
    {
        path: "checkout",
        element: <Checkout />,
    },
    {
        path: "categories",
        element: <Categories />,
    },
    {
        path: "myOrders",
        element: <MyOrders />,
    },
    {
        path: "newProducts",
        element: <NewProducts />,
    },
    {
        path: "offers",
        element: <OfferProducts />,
    },
    {
        path: "myProfile",
        element: <MyProfile />,
    },
    {
        path: "blogs",
        element: <BlogsData />,
    },
    {
        path: "*",
        element: <Error />,
    },
    {
        path: "about",
        element: <About />,
    },
    {
        path: "dashboard",
        element: <Slider />,
        children: [
            {
                index: true,
                element: <AdminDashboard />,
            },
            {
                path: "AllUsers",
                element: <User />,
            },
            {
                path: "AddProduct",
                element: <AddProducts />,
            },
            {
                path: "AllProducts",
                element: <AllProducts />,
            },
            {
                path: "updateProduct/:id",
                element: <UpdateProducts />
            },
            {
                path: "addCategory",
                element: <AddCategory />
            },
            {
                path: "AllCategories",
                element: <AllCategories />
            },
            {
                path: "updateCategory/:id",
                element: <UpdateCategory />
            },
            {
                path: "couponCode/:id",
                element: <CouponCodeEdit />
            },
            {
                path: "couponCode",
                element: <CouponCode />
            },
            {
                path: "AddCouponCode",
                element: <CouponCodeAdd />
            },
            {
                path: "requestProduct",
                element: <ReqestProducts />
            },
            {
                path: "orders",
                element: <PendingOrder />
            },
            {
                path: "Orders/:id",
                element: <OrderDetails />
            },
            {
                path: "blogs",
                element: <Blogs />
            },
            {
                path: "blogsAdd",
                element: <BlogsAdd />
            },
            {
                path: "employee",
                element: <Employee />
            },
            {
                path: "addSize",
                element: <AddSize />
            },
            {
                path: "allSize",
                element: <AllSize />
            },
            {
                path: "addNews",
                element: <AddNews />
            },
            {
                path: "addNumber",
                element: <AddNumber />
            },
            {
                path: "showNumber",
                element: <ShowNumber />
            },
            {
                path: "addBanner",
                element: <AddBanner />
            },
            {
                path: "showBanner",
                element: <ShowBanner />
            },
            {
                path: "addDiscountImg",
                element: <AddDiscountImg />
            },
            {
                path: "showDiscountImg",
                element: <DiscountImage />
            },
            {
                path: "paidOrder",
                element: <PaidOrder />
            },
            {
                path: "completeOrder",
                element: <CompleteOrder />
            },
            {
                path: "completeOrderDetails/:id",
                element: <CompleteOrderDetails />
            },
        ],
    },
    {
        path: "/", // Default route should be at the end
        element: <Home />,
    },
]);

export default routes;
