// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDxCOuoqC95FoMbu4hqF_JdvNSrRE57sOU",
    authDomain: "e-commerce-4d13f.firebaseapp.com",
    projectId: "e-commerce-4d13f",
    storageBucket: "e-commerce-4d13f.appspot.com",
    messagingSenderId: "27150241579",
    appId: "1:27150241579:web:0057adcd8c4079b2cf3f6e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;