import React, { useEffect, useState } from 'react';
import Navbar from '../layout/main/Navbar';
import { GoTrash } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, updateQuantity } from '../redux/features/AddToCart';
import { toast } from 'react-toastify';
import { RiEditBoxFill } from 'react-icons/ri';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../firebase.init';
const Carts = () => {
    const { products, total } = useSelector(state => state?.AddToCart)
    const dispatch = useDispatch()
    const handleRemoveFromCart = (productId, productSize) => {
        const userConfirmed = window.confirm("Are you sure you want to remove?");
        if (userConfirmed) {
            dispatch(removeFromCart({ _id: productId, size: productSize }));
            toast.success(`Removing Successfully`)
        }
    };
    const handleQuantityChange = (productId, quantity) => {
        dispatch(updateQuantity({ _id: productId, quantity }));
    };

    // my Orders 
    const [myOrders, setMyOrders] = useState([]);
    const [user] = useAuthState(auth);

    useEffect(() => {
        const email = user?.email;
        const url = `https://api.rebzigo.com/myitems?email=${email}`; fetch(url)
            .then((res) => res.json())
            .then((data) => setMyOrders(data));
    }, [user?.email]);
    return (
        <div>
            <Navbar />
            <section class="mt-4 h-screen">
                <div class="py-1 mx-auto max-w-7xl lg:py-2">
                    <div class="mb-8 ">
                        {
                            products?.map(product => <div class="mb-6 bg-white shadow-md">
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center gap-10">
                                        <div class="">
                                            <div class="w-full md:w-1/3">
                                                <div class="w-full md:h-24 md:w-24">
                                                    <img src={product?.topimg} alt=""
                                                        class="object-cover lg:w-full w-20 h-full" />
                                                </div>
                                            </div>

                                        </div>
                                        <div >
                                            <div class="md:flex items-center justify-between md:w-60">
                                                <div>
                                                    <h2 class="mb-1 text-sm md:text-xl font-bold ">{product?.productName}</h2>
                                                    <div class="w-auto text-right md:w-1/6 lg:w-2/12 ">
                                                        <p class="text-lg font-bold text-primary flex gap-1 items-center">{parseInt(product?.sellPrice) * parseInt(product?.quantity)} <span className='text-sm font-normal'>Bdt</span> </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className='md:w-20 flex md:justify-between '> <span className=''>Size: </span> <span className='font-bold'>  {product.size}</span></h1>
                                                    <h1 className='w-20 flex justify-between'> <span>Quantity :</span>  <span className='font-bold'>{product.quantity}</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex justify-end gap-10 items-center mx-4'>
                                        <div>
                                            <Link to={`/products/${product?._id}`} className=' cursor-pointer flex items-center gap-2 mt-4'><RiEditBoxFill size={30} /></Link>
                                        </div>
                                        <div>
                                            <h1 onClick={() => handleRemoveFromCart(product?._id, product.size)} className=' cursor-pointer flex items-center gap-2 mt-4'><GoTrash size={30} /></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    {
                        products?.length === 0 && myOrders.length === 0 ? <h1 className='text-center text-xl tracking-wider'>No Cart Found</h1> : <div>
                            {
                                products?.length === 0 ? null : <div class="flex justify-end md:mb-6">
                                    <h1 className='text-2xl font-bold mr-3'>Total Price - {total}</h1>
                                </div>
                            }
                            <div>
                                {
                                    myOrders.length === 0 && products.length !== 0 && <div className=''>
                                        <Link to='/checkout' ><button className=' py-4 transition duration-300 hover:bg-transparent hover:text-black border border-primary text-lg bg-primary text-white w-full'>Order Now</button></Link>
                                    </div>
                                }

                                {
                                    myOrders.length !== 0 && products.length === 0 && (
                                        <div className='mt-2'>
                                            <Link to='/myOrders' >
                                                <button className='py-4 transition duration-300 hover:bg-transparent hover:text-black border border-primary text-lg bg-primary text-white w-full'>Check Order
                                                </button>
                                            </Link>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                myOrders.length !== 0 && products.length !== 0 && <div className='mt-2 flex justify-around items-center w-full gap-4'>
                                    <div className='w-full'>
                                        <Link to='/checkout' ><button className='py-4 transition duration-300 hover:bg-transparent hover:text-black border border-primary text-lg bg-primary text-white w-full'>Order Now</button></Link>
                                    </div>
                                    <div className='w-full'>
                                        <Link to='/myOrders' > <button className=' py-4 transition duration-300 hover:bg-transparent hover:text-black border border-primary text-lg bg-primary text-white w-full'>Check Order</button></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>
        </div>
    );
};
export default Carts;