import React, { useEffect, useState } from 'react';
import Navbar from '../../layout/main/Navbar';

const BlogsData = () => {
    const [blogs, setBlogs] = useState([])
    useEffect(() => {
        const url = 'https://api.rebzigo.com/blogs';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setBlogs(data)
            })
    }, [])
    return (
        <>
            <Navbar />
            <div class="w-11/12 mx-auto mt-20">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-10">
                    {
                        blogs.map(item => <div class="w-full px-4 mb-6">
                            <div class="relative overflow-hidden rounded h-72">
                                <img class="object-cover w-full h-full transition-all hover:scale-110"
                                    src={item?.topimg} alt="" />
                            </div>
                            <div class="pt-6">
                                <span class="block mb-2 text-xs font-semibold text-blue-700 uppercase dark:text-blue-300">
                                    {item?.createdAt}
                                </span>
                                <h2 class="mb-4 text-2xl font-bold leading-9 text-blue-800 ">
                                    {item?.title}
                                </h2>
                                <p class="mb-4 text-base leading-7 text-gray-400">
                                    {item?.description}
                                </p>
                                <a class="flex items-center text-sm font-semibold" href="#">
                                    <div
                                        class="flex items-center px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                                        More Details
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                                            class="ml-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </>
    );
};

export default BlogsData;