/* eslint-disable no-octal-escape */
import React, { useEffect, useState } from 'react';
import bkash from "../../Assets/bkash1.svg";
import Nogod from "../../Assets/nogod.png";
import cod from "../../Assets/cod-pay.png"
import Navbar from '../../layout/main/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { clearCart } from '../../redux/features/AddToCart';
import { useNavigate } from 'react-router-dom';
import useNumber from '../../hooks/useNumber';
const Checkout = () => {
    const [user] = useAuthState(auth)
    const { products, total, purchaseTotal } = useSelector(state => state?.AddToCart)
    const [productData, setProductData] = useState([])
    const dispatch = useDispatch();
    const [deliveryCharge, setDeliveryCharge] = useState(60);

    useEffect(() => {
        fetch('https://api.rebzigo.com/product')
            .then((response) => response.json())
            .then((data) => setProductData(data))
            .catch((error) => console.error('Error fetching products: ', error));
    }, []);
    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        email: user?.email,
        address: '',
        transactionId: '',
        upzila: '',
        district: '',
        phone2: ''
    });
    const [selectedMethod, setSelectedMethod] = useState('cod');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const navigate = useNavigate()
    const handleImageClick = (method) => {
        setSelectedMethod(method);
    };
    // apply coupon code 
    const [couponCode, setCouponCode] = useState('');
    const [discountedTotal, setDiscountedTotal] = useState(total);
    const [couponCodes, setCouponCodes] = useState('')
    const handleCouponCodeChange = (e) => {
        setCouponCode(e.target.value);
    };
    const [myCouponCode, setmyCouponCode] = useState([])
    const [couponId, setCouponId] = useState([])
    const [showNumber] = useNumber()
    useEffect(() => {
        const url = 'https://api.rebzigo.com/couponCode';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setmyCouponCode(data)
            })
    }, [])
    // using coupon code get discount 
    const applyDiscount = () => {
        const coupon = myCouponCode.find(coupon => coupon.code === couponCode);
        setCouponId(coupon)
        if (coupon) {
            const discountPercentage = parseInt(coupon.discount, 10); // Convert discount to an integer
            const discount = total * (discountPercentage / 100); // Calculate the discount based on the integer value
            const newTotal = total - discount;
            setDiscountedTotal(newTotal);
            setCouponCodes(couponCode);
        } else {
            toast.error('Invalid coupon code. Please enter a valid coupon code.');
        }
    };
    const date = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).replace(/,/g, '');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Phone Number Regex Verify 
        const phonePattern = /^01[0-9]{9}$/;
        if (!phonePattern.test(formData.phone)) {
            toast.error('Invalid phone number. Please use the correct format: 01756XXXXXX');
            return;
        }
        const orderedProducts = [];
        for (const item of products) {
            const product = productData?.find((p) => p?._id === item?._id);
            if (product && item?.quantity <= product?.stock) {
                orderedProducts.push({
                    _id: product._id,
                    quantity: item.quantity,
                    name: item.productName,
                    pprice: item?.purchasePrice,
                    img: item?.topimg,
                    size: item?.size,
                    sellPrice: item?.sellPrice,
                    code: item?.productCode
                });
            } else {
                toast.error(`Insufficient stock for ${product.productName}`);
                return;
            }
        }
        const status = "Pending"
        const sell = 'totalSell'
        const benifit = 'totalBenifit'
        const order = 'totalOrder'
        const postData = {
            ...formData,
            paymentMethod: selectedMethod,
            orderedProducts,
            discountedTotal: parseFloat(total) + parseFloat(deliveryCharge),
            couponCodes,
            couponId,
            status,
            total,
            purchaseTotal,
            sell,
            benifit,
            order,
            date,
            deliveryCharge: deliveryCharge
        };
        fetch('https://api.rebzigo.com/orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // Update product stock in the database after a successful order
                orderedProducts.forEach((orderedProduct) => {
                    const product = productData?.find((p) => p._id === orderedProduct._id);
                    if (product) {
                        const newStock = product.stock - orderedProduct.quantity;
                        fetch(`https://api.rebzigo.com/products/${product._id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ stock: newStock }),
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error('Failed to update product stock');
                                }
                            })
                            .catch((error) => {
                                console.error('Error updating product stock: ', error);
                            });
                    }
                });
                toast.success('Order submitted successfully. Check your Order Page.');
                dispatch(clearCart());
                navigate("/myOrders")
            })
            .catch((error) => {
                toast.error("Order couldn't be submitted");
            });
    };
    return (
        <div>
            <Navbar />
            <div class="sm:px-10 md:w-8/12 mx-auto">
                <form onSubmit={handleFormSubmit} class="md:mt-10 px-4 md:pt-8 pt-2 lg:mt-0">
                    <p class="md:text-2xl text-xl my-3 font-bold tracking-widest text-center">Order Info</p>
                    <div class="">
                        <h1 className='mt-4'>Contact Info</h1>
                        <div className='flex gap-5'>
                            <div>
                                <label for="fullName" class="mt-4 mb-2 block text-sm font-medium">Full Name</label>
                                <div class="relative">
                                    <input type="text" id="fullName" name="fullName" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize  shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Your full name here"
                                        onChange={handleInputChange}
                                        required />
                                </div>
                            </div>
                            <div>
                                <label for="phone" class="mt-4 mb-2 block text-sm font-medium">Phone</label>
                                <div class="relative">
                                    <input type="number" id="phone" name="phone" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="0182****25"
                                        required
                                        onChange={handleInputChange}
                                        pattern="^\01[0-9]{9}$"
                                    />
                                </div>
                            </div>
                        </div>
                        <label for="phone2" class="mt-4 mb-2 block text-sm font-medium">Phone 2</label>
                        <div class="relative">
                            <input type="number" id="phone2" name="phone2" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="0182****25"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className='flex items-center gap-5'>
                            <div>
                                <label for="District" class="mt-4 mb-2 block text-sm font-medium">District</label>
                                <div class="relative">
                                    <input type="text" id="district" name="district" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Dhaka"
                                        onChange={handleInputChange} required
                                    />
                                </div>
                            </div>
                            <div>
                                <label for="upzila" class="mt-4 mb-2 block text-sm font-medium">UpZila</label>
                                <div class="relative">
                                    <input type="text" id="upzila" name="upzila" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Mirpur"
                                        onChange={handleInputChange}
                                        required
                                    ></input></div>
                            </div>
                        </div>
                        <div>
                            <label for="address" class="mt-4 mb-2 block text-sm font-medium">Address</label>
                            <div class="relative">
                                <input type="text" id="address" name="address" class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Detailed Address"
                                    onChange={handleInputChange}
                                    required />
                            </div>
                        </div>
                        <div>

                        </div>
                        {/* Payment Method  */}
                        <p class="my-2 text-lg font-medium">Payment Methods</p>
                        <div className='flex items-center gap-6 mt-5'>
                            <div className='relative'>
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="cod"
                                    className='absolute bottom-8 left-2'
                                    checked={selectedMethod === 'cod'}
                                    onChange={() => handleImageClick('cod')}
                                />
                                <label htmlFor="cod" onClick={() => handleImageClick('cod')}>
                                    <img className='w-36 bg-white shadow-md h-20 px-8 py-4' src={cod} alt="" />
                                </label>
                            </div>

                            <div className='relative'>
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="nogod"
                                    className='absolute bottom-8 left-2'
                                    checked={selectedMethod === 'nogod'}
                                    onChange={() => handleImageClick('nogod')}
                                />
                                <label htmlFor="nogod" onClick={() => handleImageClick('nogod')}>
                                    <img className='w-36 bg-white shadow-md h-20 px-8 py-4' src={Nogod} alt="" />
                                </label>
                            </div>
                            <div className='relative'>
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    id="bkash"
                                    className='absolute bottom-8 left-2'
                                    checked={selectedMethod === 'bkash'}
                                    onChange={() => handleImageClick('bkash')}
                                />
                                <label htmlFor="bkash" onClick={() => handleImageClick('bkash')}>
                                    <img className='w-40 bg-white shadow-md h-20 px-8 py-4' src={bkash} alt="" />
                                </label>
                            </div>
                        </div>
                        {
                            selectedMethod !== 'cod' && <div className='my-4 tracking-widest text-sm flex gap-4 items-center'>
                                {
                                    showNumber?.map(item => <div>
                                        <h1 className='text-pink-500 font-bold'><span className=' text-orange-500'>{item?.method}</span> - {item?.number}</h1>
                                    </div>)
                                }
                            </div>
                        }
                        {
                            selectedMethod !== 'cod' && <>
                                <label for="transaction-id" class="mt-4 mb-2 block text-sm font-medium">Transition ID</label>
                                <div class="relative">
                                    <input type="text" id="transaction-id"
                                        name="transactionId" class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm capitalize shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Your Transition ID"
                                        required={selectedMethod !== 'cod'}
                                        onChange={handleInputChange} />
                                </div>
                            </>
                        }
                    </div>
                    <input type='submit' value="Place Order" class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white cursor-pointer" />
                    <h2 className='flex items-center justify-between'>Sub Total: <span>{total} bdt</span></h2>
                    <h1 className='py-3 flex items-center justify-between'>Shipping:  <span>{deliveryCharge} dbt</span></h1>
                    <div class="flex items-center justify-between mb-3">
                        <p class="text-md font-medium text-gray-900">Total</p>
                        <p class="text-xl font-semibold text-gray-900">{(total + deliveryCharge).toFixed(2)} Bdt</p>
                    </div>

                    {
                        couponCodes !== '' ? <>
                            <hr />
                            <div class="mt-6 flex items-center justify-between mb-3">
                                <p class="text-sm font-medium text-gray-900">Cashback</p>
                                <p class="text-xl font-semibold text-gray-900">-{(parseFloat(total) - parseFloat(discountedTotal)).toFixed(2)} Bdt</p>
                            </div>
                            <hr />
                            <div class="mt-6 flex items-center justify-between mb-3">
                                <p class="text-sm font-medium text-gray-900">Discount Total</p>
                                <p class="text-xl font-semibold text-gray-900">{(discountedTotal).toFixed(2)} Bdt</p>
                            </div>

                        </> : ''
                    }
                </form>
                <div className='mb-4'>
                    <input value={couponCode}
                        onChange={handleCouponCodeChange} class="w-full rounded-md border border-gray-200 px-4 py-3  text-sm  shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" type="text" placeholder='Enter Coupon Code Here..' />
                    <button onClick={applyDiscount} className='w-44 mt-4 mx-auto py-3 flex justify-center bg-primary text-white border border-primary hover:bg-transparent transition duration-300 hover:text-primary rounded-md'> Add Coupon Code</button>
                </div>
            </div>
        </div>
    );
};

export default Checkout;