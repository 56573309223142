import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const OrderDetails = () => {
    const [orders, setOrders] = useState([])
    const { id } = useParams()
    useEffect(() => {
        const url = `https://api.rebzigo.com/orders/${id}`
        fetch(url)
            .then(res => res.json())
            .then(data => setOrders(data))
    }, [id])
    const lastPrice = orders?.lastPrice ? parseFloat(orders?.lastPrice) : 0;

    return (
        <div className='mt-14 w-10/12 mx-auto'>
            <div className='font-bold text-lg w-8/12'>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Name  <span>-</span></span> <span className='ml-10'>{orders?.fullName}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Phone  <span>-</span></span> <span className='ml-10'>{orders?.phone}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Phone 2  <span>-</span></span> <span className='ml-10'>{orders?.phone2}</span></h1>

                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>District  <span>-</span></span> <span className='ml-10'>{orders?.district}</span></h1>

                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Upzila  <span>-</span></span> <span className='ml-10'>{orders?.upzila}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Address  <span>-</span></span> <span className='ml-10'>{orders?.address}</span></h1>

                <h1 className='text-red-500 flex items-center justify-between'>
                    <span className='flex w-60 justify-between items-center'> Price <span></span>-</span> {parseFloat(orders?.discountedTotal) - lastPrice === 0
                        ? "paid"
                        : (
                            <span className='ml-10'>
                                <span>
                                    {lastPrice ? (
                                        <>{parseFloat(orders.discountedTotal).toFixed(2)} - {parseFloat(orders?.lastPrice).toFixed(2)} = {(
                                            parseFloat(orders?.discountedTotal) - lastPrice
                                        ).toFixed(2)}</>
                                    ) : (
                                        <>{parseFloat(orders.discountedTotal).toFixed(2)} - 0 = {(
                                            parseFloat(orders?.discountedTotal) - 0
                                        ).toFixed(2)}</>
                                    )}

                                </span>
                            </span>
                        )
                    }
                </h1>

                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>email  <span>-</span></span> <span className='ml-10'>{orders?.email}</span></h1>                 <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Order Id  <span>-</span></span> <span className='ml-10'>{orders?._id}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Delivery Id  <span>-</span></span> <span className='ml-10'>{orders?.deliveryId ? orders?.deliveryId : "Not Found"}</span></h1>
                <table className="table-auto w-8/12 mt-6 ">
                    <thead>
                        <tr className="bg-primary/40 text-center font-mono">
                            <th
                                className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                            >
                                SL
                            </th>
                            <th
                                className="
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                            >
                                Code
                            </th>
                            <th
                                className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                            >
                                Price
                            </th>
                            <th
                                className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                            >
                                Size
                            </th>
                            <th
                                className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                            >
                                Quantity
                            </th>
                        </tr>
                    </thead>
                    {orders.orderedProducts?.map((item, index) => (
                        <tbody>
                            <tr className="">
                                <td
                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-l border-primary
                               "
                                >
                                    {index + 1}
                                </td>
                                <td
                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                >
                                    {item?.code}
                                </td>
                                <td
                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                >
                                    <div className=" justify-center flex items-center gap-2">
                                        {parseFloat(item?.sellPrice) * parseFloat(item?.quantity).toFixed(2)}
                                    </div>
                                </td>
                                <td
                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                >
                                    <div className=" justify-center flex items-center gap-2">
                                        {item?.size}
                                    </div>
                                </td>
                                <td
                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                >
                                    <div className=" justify-center flex items-center gap-2">
                                        {item?.quantity}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>

            <div className='font-bold text-lg mt-20 w-8/12'>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Name  <span>-</span></span> <span className='ml-10'>{orders?.fullName}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Phone  <span>-</span></span> <span className='ml-10'>{orders?.phone}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Phone 2  <span>-</span></span> <span className='ml-10'>{orders?.phone2}</span></h1>

                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>District  <span>-</span></span> <span className='ml-10'>{orders?.district}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Upzila  <span>-</span></span> <span className='ml-10'>{orders?.upzila}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Address  <span>-</span></span><span className='ml-10'>{orders?.address}</span></h1>
                <h1 className='text-red-500 flex items-center justify-between'>
                    <span className='flex w-60 justify-between items-center'> Price <span></span>-</span> {parseFloat(orders?.discountedTotal) - lastPrice === 0
                        ? "paid"
                        : (
                            <span className='ml-10'>
                                <span>
                                    {lastPrice ? (
                                        <>{parseFloat(orders.discountedTotal).toFixed(2)} - {parseFloat(orders?.lastPrice).toFixed(2)} = {(
                                            parseFloat(orders?.discountedTotal) - lastPrice
                                        ).toFixed(2)}</>
                                    ) : (
                                        <>{parseFloat(orders.discountedTotal).toFixed(2)} - 0 = {(
                                            parseFloat(orders?.discountedTotal) - 0
                                        ).toFixed(2)}</>
                                    )}

                                </span>
                            </span>
                        )
                    }
                </h1>

                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>email  <span>-</span></span> <span className='ml-10'>{orders?.email}</span></h1>                 <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Order Id  <span>-</span></span> <span className='ml-10'>{orders?._id}</span></h1>


                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Coupon Coder Name  <span>-</span></span> <span className='ml-10'>{orders?.couponId?.name}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Coupon Coder Discount  <span>-</span></span> <span className='ml-10'>{orders?.couponId?.discount}</span></h1>
                <h1 className='flex justify-between'> <span className='flex w-60 justify-between items-center'>Coupon Code  <span>-</span></span> <span className='ml-10'>{orders?.couponId?.code}</span></h1>

            </div>
        </div>
    );
};

export default OrderDetails;