import React, { useEffect, useState } from 'react';
import Navbar from '../../layout/main/Navbar';
import Card from '../../components/ProductsCard/Card';
import Loading from '../../shared/Loading';
import Footer from '../../shared/Footer';

const OfferProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = "https://api.rebzigo.com/product";
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setProducts(data)
                setLoading(false)
            })
    }, []);

    // Filter products with a discount greater than 0
    const discountProducts = products.filter(product => product.discount > 0);
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <Navbar />
            <div className='bg-gray-100 pt-10'>
                <h1 className='text-center text-2xl font-semibold tracking-wider'>Our Offers Products</h1>
                <div className='py-20 mt-5 w-11/12 md:w-11/12 lg:w-11/12 mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6'>
                    {
                        discountProducts.map(product => (
                            <Card key={product.id} product={product}></Card>
                        )
                        )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OfferProducts;
