import { useEffect, useState } from "react";

const useToken = (user) => {
    const [token, setToken] = useState('');
    useEffect(() => {
        const email = user?.user?.email
        const name = user?.user?.displayName
        let currentUser = { email, name, role: "user" }
        if (email) {
            fetch(`https://api.rebzigo.com/newUser/${email}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(currentUser)
            })
                .then(res => res.json())
                .then(data => {
                })
        }
    }, [user])

    return [token];
}

export default useToken;
