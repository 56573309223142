import { useEffect, useState } from "react";

const UseAdmin = (user) => {
    const [admin, setAdmin] = useState(false);
    const [adminLoading, setadminLoading] = useState(true);
    useEffect(() => {
        const email = user?.email;
        if (email) {
            fetch(`https://api.rebzigo.com/admin/${email}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setAdmin(data.admin);
                    setadminLoading(false);
                });
        }
    }, [user]);

    return [admin, adminLoading];
};

export default UseAdmin;
