import React, { useEffect, useState } from 'react';
import { FaBangladeshiTakaSign } from "react-icons/fa6";
import Modal from 'react-modal';
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { Link } from 'react-router-dom';

const MontlyDataOrder = () => {
    const [orders, setOrders] = useState([])
    const [MonthlySell, setMonthlySell] = useState([])
    const [MonthlyBenifit, setMonthlyBenifit] = useState([])
    const [MonthlyOrder, setMonthlyOrder] = useState([])
    const [CompleteOrder, setCompleteOrder] = useState([])
    const reverseMonthlySell = [...MonthlySell].reverse()
    const reverseMonthlyBenifits = [...MonthlyBenifit].reverse()
    const reverseMonthlyOrder = [...MonthlyOrder].reverse()
    const reverseCompleteOrder = [...CompleteOrder].reverse()
    const url = "https://api.rebzigo.com/orders";
    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setOrders(data)
                const totalSellData = data.filter(order => order.sell === 'Monthly Sell');
                setMonthlySell(totalSellData);
                const totalBenifitData = data.filter(order => order.benifit === 'Monthly Benifit');
                setMonthlyBenifit(totalBenifitData);
                const totalOrderData = data.filter(order => order.order === 'Monthly Order');
                setMonthlyOrder(totalOrderData);
                const CompleteOrderData = data.filter(order => order.status === 'Shipping');
                setCompleteOrder(CompleteOrderData)
            })
    }, [orders, MonthlyBenifit, MonthlySell, MonthlyOrder])
    // Total Price By Total sell Total Benifit, Total order 
    const MonthlySellPrice = MonthlySell.reduce((total, order) => total + (order.discountedTotal || 0), 0);
    // Total Benifit 
    const MonthlySellPriceData = MonthlyBenifit.reduce((total, order) => total + (order.discountedTotal || 0), 0);
    const MonthlyBenifitPriceData = MonthlyOrder.reduce((total, order) => total + (order.purchesTotal || 0), 0);
    const MonthlyBenifits = parseFloat(MonthlySellPriceData) - parseFloat(MonthlyBenifitPriceData)

    const [isSellModalOpen, setIsSellModalOpen] = useState(false);
    const openSellModal = () => setIsSellModalOpen(true);
    const closeSellModal = () => setIsSellModalOpen(false);

    // State variables and functions for Today Benifit modal
    const [isBenifitModalOpen, setIsBenifitModalOpen] = useState(false);
    const openBenifitModal = () => setIsBenifitModalOpen(true);
    const closeBenifitModal = () => setIsBenifitModalOpen(false);

    // State variables and functions for Today Order modal
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const openOrderModal = () => setIsOrderModalOpen(true);
    const closeOrderModal = () => setIsOrderModalOpen(false);

    const [CompleteOrderModal, setCompleteOrderModalOpen] = useState(false);
    const closeCompleteOrderModal = () => setCompleteOrderModalOpen(false);

    return (
        <div className='mt-10'>
            <div>
                <div className="grid grid-cols-4">
                    <div className='grid grid-cols-1 md:grid-cols-3 w-11/12 mx-auto col-span-3'>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Monthly Sell</h1>
                            <h1 className='flex items-center gap-1 justify-center'><FaBangladeshiTakaSign />{MonthlySellPrice}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openSellModal}>Show</button>                            </div>
                        </div>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Monthly Benifit</h1>
                            <h1 className='flex items-center gap-1 justify-center'><FaBangladeshiTakaSign />{MonthlyBenifits.toFixed(2)}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openBenifitModal}>Show</button>                            </div>
                        </div>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Monthly Order</h1>
                            <h1 className='flex items-center gap-1 justify-center'><MdOutlineBookmarkBorder />{MonthlyOrder.length}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openOrderModal}>Show</button>                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-11/12 text-center py-5">
                        <h1 className="">Paid Orders</h1>
                        <h1>{reverseCompleteOrder?.length}</h1>
                        <Link to='/dashboard/paidOrder' className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white'>Show</Link>
                    </div>
                </div>
            </div>
            {/* Total Sell Modal  */}
            <Modal
                isOpen={isSellModalOpen}
                onRequestClose={closeSellModal}
                contentLabel="Today Sell Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeSellModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseMonthlySell?.map(sell => <div key={sell?._id} className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'>Total Price - <span className='text-sm'><FaBangladeshiTakaSign /></span> {sell?.discountedTotal}</h1>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
            {/* Total Benifits Modal  */}
            <Modal
                isOpen={isBenifitModalOpen}
                onRequestClose={closeBenifitModal}
                contentLabel="Today Benifit Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeBenifitModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseMonthlyBenifits?.map(sell => <div key={sell?._id} className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Benifits - </span> <span className='text-sm'><FaBangladeshiTakaSign /></span> {(parseFloat(sell?.discountedTotal) - parseFloat(sell?.purchesTotal)).toFixed(0, 2)}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Sell Price - </span> <span className='text-sm'><FaBangladeshiTakaSign /></span>{sell?.discountedTotal} </h1>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
            {/* Total Order Modal  */}
            <Modal
                isOpen={isOrderModalOpen}
                onRequestClose={closeOrderModal}
                contentLabel="Today Order Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeOrderModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseMonthlyOrder?.map(sell => <div key={sell?._id} className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Product - </span> {sell?.orderedProducts?.length} </h1>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
            {/* Total Complete Order Modal  */}
            <Modal
                isOpen={CompleteOrderModal}
                onRequestClose={closeCompleteOrderModal}
                contentLabel="Complete Order Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeCompleteOrderModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseCompleteOrder?.map(sell => <div key={sell?._id} className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='font-semibold text-md tracking-wider'>Order - {sell?.status}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Product - </span> {sell?.orderedProducts?.length} </h1>
                                </div>
                                <hr />
                                <div>
                                    {
                                        sell?.orderedProducts?.map((item, index) => <div key={item?._id} className='flex items-center gap-10 font-semibold w-10/12 justify-between mt-2'>

                                            <h1>{index + 1} {item?.name}</h1>
                                            <h1 className='flex items-center'><FaBangladeshiTakaSign />{(parseFloat(item?.sellPrice) * parseFloat(item?.quantity))}</h1>
                                            <h1>{item?.quantity} <span>item</span></h1>
                                        </div>)
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MontlyDataOrder;