import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules'; //Pagination
import { useEffect } from 'react';
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { setSelectedCategory } from '../../redux/features/CategorySlice';
import { Link, useNavigate } from 'react-router-dom';
const Categories = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const [category, setcategory] = useState([])

    useEffect(() => {
        const url = 'https://api.rebzigo.com/category'
        fetch(url)
            .then(res => res.json())
            .then(data => setcategory(data))
    }, [])

    const categoryTree = {};

    category.forEach((item) => {
        const { category, subCategory } = item;
        if (!categoryTree[category]) {
            categoryTree[category] = { subcategories: [subCategory] };
        } else {
            categoryTree[category].subcategories.push(subCategory);
        }
    });

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = (data) => {
        dispatch(setSelectedCategory(data))
        navigate("/categories")
    }
    return (
        <div className='overflow-hidden bg-white'>
            <div className='relative'>
                <div className='md:w-11/12 mx-auto grid grid-cols-3 my-2 md:my-3 w-[98%]'>
                    <div className='flex items-center gap-1 md:gap-2'>
                        <Link to="/newProducts" className='lg:px-4 px-[2px] py-2 md:py-4 shadow-md mr-1 lg:mr-6  rounded-sm lg:w-52 bg-[#C6C7C8]'>
                            <h1 className='text-center lg:text-xl text-[8.5px] md:tracking-wider md:font-semibold font-normal'>New Product</h1>
                        </Link>
                        <Link to='/offers' className='md:px-4 px-2 py-2 md:py-4 shadow-md  lg:mr-6 mr-1 lg:w-52  rounded-sm bg-[#C6C7C8]'>
                            <h1 className='text-center text-[10px] lg:text-xl tracking-wider md:font-semibold'>Offer</h1>
                        </Link>
                    </div>
                    <div className='col-span-2'>

                        <Swiper
                            slidesPerView={3}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={true}
                            navigation={{
                                nextEl: '.custom-swiper-button-next',
                                prevEl: '.custom-swiper-button-prev',
                            }}
                            modules={[Autoplay, Navigation]} // Pagination
                            className={{
                                pagination: "custom-pagination",
                            }}
                            onSlideChange={handleSlideChange}

                        >     {
                                Object.entries(categoryTree).map(([mainCategory, data], index) => <SwiperSlide className={`custom-swiper-slide ${index === activeIndex ? 'swiper-active' : ''}`}>
                                    <div className='lg:w-52 w-auto lg:px-4 px-1 py-2 md:py-4 shadow-md bg-[#C6C7C8] mr-1 lg:mr-6 rounded-sm flex flex-col'>
                                        <h1 id="0" onClick={() => handleClick(mainCategory)} className='text-center lg:text-xl text-[10px] cursor-pointer tracking-wider md:font-semibold'>
                                            {mainCategory}
                                        </h1>
                                    </div>


                                </SwiperSlide>

                                )
                            }
                        </Swiper>
                    </div>
                </div>
                <div className="cursor-pointer custom-swiper-navigation flex items-center gap-5">
                    <div className=" absolute md:top-6 top-2 md:left-2 transition duration-300 custom-swiper-button-prev text-md md:text-xl cursor-pointer  rounded-full text-black">
                        <MdArrowBackIosNew />
                    </div>
                    <div className="absolute top-2 md:top-6 md:right-2 right-0 transition duration-300 custom-swiper-button-prev text-md md:text-xl cursor-pointer rounded-full text-black">
                        <MdArrowForwardIos />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Categories;
