import { useState } from "react";
import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AllProducts = () => {
    const [products, setProducts] = useState([]);
    const [filter, setFilter] = useState("");
    useEffect(() => {
        const url = "https://api.rebzigo.com/product"
        fetch(url)
            .then(res => res.json())
            .then(data => setProducts(data))
    }, [])
    const searchEvent = (event) => {
        setFilter(event.target.value);
    };
    const dataSearch = products?.filter((item) => {
        return Object?.keys(item)?.some((key) => {
            const value = item[key];
            return (
                value !== null &&
                value !== undefined &&
                value
                    .toString()
                    .toLowerCase()
                    .includes(filter.toString().toLowerCase())
            );
        });
    });
    const reverse = [...dataSearch]?.reverse();
    const navigate = useNavigate()
    const handleUpdateProduct = (id) => {
        navigate(`/dashboard/updateProduct/${id}`);
    }
    const handleDelete = (id) => {
        const proced = window.confirm("Are Your Sure Delete This User");
        if (proced) {
            const url = `https://api.rebzigo.com/products/${id}`;
            fetch(url, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {

                    const reamingData = products.filter((user) => user?._id !== id);
                    setProducts(reamingData);
                    toast.success("Succesfully Delete Products");
                });
        }
    };

    return (
        <section className="my-4">
            <h1 className="text-2xl font-bold text-center mb-4">Products Managment</h1>
            <div className="flex items-center w-10/12 mx-auto justify-between my-4">
                <div className="relative overflow-hidden w-8/12 p-0 border-[1px] border-primary rounded-full">
                    <input
                        type="text"
                        className="placeholder-secondary border-none px-6 pl-10 md:text-sm text-[8px] font-mono input py-4 w-full rounded-full text-secondary"
                        value={filter}
                        placeholder="Search Your Products"
                        onChange={searchEvent.bind(this)}
                    />
                    <h1 className=' absolute top-1/3 left-4 text-primary'><BsSearch /></h1>
                </div>
                <Link to="/dashboard/AddProduct" className="bg-primary text-white tracking-wider border border-primary hover:bg-transparent hover:text-primary transition duration-300 md:px-4 px-1 py-2 cursor-pointer">Add Products</Link>
            </div>
            <div className="">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono ">
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               border-l border-transparent
                               font-mono
                               "
                                        >
                                            Product Image
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                                        >
                                            Name
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Stock
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Product Code
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Purchase  Price
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Sell  Price
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Discount
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                              text-sm
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {reverse?.map((item) => (
                                    <tbody>
                                        <tr className="font-mono">
                                            <td

                                            >
                                                <img className="w-20 h-20 mx-auto" src={item?.topimg} alt="" />
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5 text-sm
                                bg-transparent
                               border-b border-l border-primary
                               "
                                            >
                                                {item?.productName}
                                            </td>

                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.stock}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.productCode}
                                            </td>
                                            <td className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "

                                            >
                                                {item?.purchasePrice}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                {item?.sellPrice}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                {item?.discount ? item.discount : 0}%
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-1 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                            >
                                                <div className=" flex items-center gap-2">
                                                    <h1 onClick={() => handleDelete(item?._id)}
                                                        className="border-r border-primary px-2 text-red-500">Delete</h1>
                                                    <h1 onClick={() => handleUpdateProduct(item?._id)}>Edit</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllProducts;
