import { useState } from "react";
import { useEffect } from "react";
const Employee = () => {
    const [users, setUsers] = useState([]);
    const [couponCodeMatch, setCouponCodeMatch] = useState([]);

    useEffect(() => {
        const userUrl = 'https://api.rebzigo.com/new';
        fetch(userUrl)
            .then(res => res.json())
            .then(data => {
                const employeeUsers = data.filter(user => user.role === 'employee');
                setUsers(employeeUsers);
            })
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    // Coupon Code Match
    useEffect(() => {
        const couponCodeUrl = 'https://api.rebzigo.com/couponCode';
        fetch(couponCodeUrl)
            .then(res => res.json())
            .then(data => {
                const filteredCouponCodes = data.filter(code => users.map(user => user.email).includes(code.email));
                setCouponCodeMatch(filteredCouponCodes);
            })
            .catch(error => console.error('Error fetching coupon codes:', error));
    }, [users]);
    // Order Summery 

    const [orders, setOrders] = useState([])
    useEffect(() => {
        const url = "https://api.rebzigo.com/orders"
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const filterOrders = data.filter(item => users.map(user => user.email).includes(item?.couponId?.email));
                setOrders(filterOrders);
            })
    }, [users])

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-us', { month: 'short' });

    return (
        <>
            <section className="my-4">
                <h1 className="text-2xl font-bold text-center mb-4">Employee Managment</h1>
                <div className="w-11/12 mx-auto">
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="max-w-full overflow-x-auto rounded-md">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-primary/40 text-center font-mono ">
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               border-l border-transparent
                               font-mono
                               "
                                            >
                                                Name
                                            </th>
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                                            >
                                                Email
                                            </th>
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                            >
                                                Coupon Code
                                            </th>
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                            >
                                                Total Earning
                                            </th>
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                            >
                                                Montly Earning
                                            </th>
                                            <th
                                                className="
                               w-1/6
                               min-w-[160px]
                               text-md
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                            >
                                                Total Order
                                            </th>
                                        </tr>
                                    </thead>
                                    {users.map((item) => (
                                        <tbody>
                                            <tr className="font-mono">
                                                <td
                                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-l border-primary
                               "
                                                >
                                                    {item?.name}
                                                </td>
                                                <td
                                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                                >
                                                    {item?.email}
                                                </td>
                                                <td
                                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                                >
                                                    {
                                                        couponCodeMatch
                                                            .filter(code => code?.email === item.email)
                                                            .map(filteredCode => filteredCode.code)
                                                            .join(', ')
                                                    }
                                                </td>
                                                <td

                                                    className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 cursor-pointer
                               bg-transparent
                               border-b border-r border-primary
                               "
                                                >
                                                    {
                                                        (orders
                                                            .filter(order => order?.couponId?.email === item?.email)
                                                            .map(filteredCode => filteredCode?.total)
                                                            .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
                                                            - orders
                                                                .filter(order => order?.couponId?.email === item?.email)
                                                                .map(filteredCode => filteredCode?.discountedTotal)
                                                                .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
                                                        ).toFixed(2)
                                                    }
                                                </td>
                                                <td
                                                    className="
        text-center
        text-dark
        font-medium
        text-secondary
        py-5
        px-2 cursor-pointer
        bg-transparent
        border-b border-r border-primary
    "
                                                >
                                                    {(
                                                        orders
                                                            .filter(order => order?.couponId?.email === item?.email)
                                                            .filter(order => {
                                                                const orderDate = new Date(order.date);
                                                                const orderMonth = orderDate.getMonth() + 1; // Months are zero-indexed
                                                                const orderYear = orderDate.getFullYear();
                                                                const currentOrderMonth = currentDate.getMonth() + 1; // Months are zero-indexed
                                                                const currentOrderYear = currentDate.getFullYear();
                                                                return orderMonth === currentOrderMonth && orderYear === currentOrderYear;
                                                            })
                                                            .map(filteredCode => parseFloat(filteredCode?.total))
                                                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                                        - orders
                                                            .filter(order => order?.couponId?.email === item?.email)
                                                            .filter(order => {
                                                                const orderDate = new Date(order.date);
                                                                const orderMonth = orderDate.getMonth() + 1; // Months are zero-indexed
                                                                const orderYear = orderDate.getFullYear();
                                                                const currentOrderMonth = currentDate.getMonth() + 1; // Months are zero-indexed
                                                                const currentOrderYear = currentDate.getFullYear();
                                                                return orderMonth === currentOrderMonth && orderYear === currentOrderYear;
                                                            })
                                                            .map(filteredCode => parseFloat(filteredCode?.discountedTotal))
                                                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                                                    ).toFixed(2)}
                                                </td>
                                                <td

                                                    className="
text-center text-dark
font-medium
text-secondary
py-5
px-2 cursor-pointer
bg-transparent
border-b border-r border-primary
"
                                                >
                                                    {
                                                        orders
                                                            .filter(order => order?.couponId?.email === item?.email).length
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Employee;
