import { useEffect, useState } from "react";
import "./TopUpAds.css";

const TopupAds = () => {
    const [modal, setModal] = useState(true); // Start with modal as true
    const [loading, setLoading] = useState(true);
    const [topUpImag, setTopImage] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetch('https://api.rebzigo.com/discount')
            .then((response) => response.json())
            .then(data => {
                const interval = setInterval(() => {
                    setLoading(false);
                    setTopImage(data);
                }, 3000);
                return () => clearInterval(interval);
            });
    }, []);

    const reverse = [...topUpImag]?.reverse();

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setModal(false);
    };

    return (
        <>
            {loading ? "" : (
                <div className="z-10">
                    {modal && (
                        <div className="popup">
                            <button
                                className="absolute -top-4 text-red-500 font-bold text-5xl right-0"
                                id="close"
                                onClick={handleCloseModal} // Close modal on button click
                            >
                                &times;
                            </button>
                            <div>
                                <div>
                                    {reverse?.slice(0, 1)?.map(item => (
                                        <img
                                            src={item?.img ? item?.img : "https://portotheme.com/html/molla/assets/images/popup/newsletter/img-1.jpg"}
                                            alt=""
                                            className="h-full"
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default TopupAds;
