import React from 'react';
import { toast } from 'react-toastify';

const CopyEmailCell = ({ email }) => {
  const handleCopy = () => {
    const textArea = document.createElement('textarea');
    textArea.value = email;

    // Make sure to set the textarea out of the viewport to make it not visible
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';

    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      toast.success('Email copied to clipboard!');
    } catch (err) {
      console.error('Unable to copy email');
    }

    document.body.removeChild(textArea);
  };

  return (
    <div
      onClick={handleCopy}
      style={{ cursor: 'pointer' }}
    >
      {email}
    </div>
  );
};

export default CopyEmailCell;
