import { useEffect, useState } from 'react';

const useProducts = () => {
    const [hookProducts, setHookProducts] = useState()
    useEffect(() => {
        const url = "https://api.rebzigo.com/product";
        fetch(url)
            .then(res => res.json())
            .then(data => setHookProducts(data))
    }, [hookProducts])
    return [hookProducts]
};

export default useProducts;