import { useState } from "react";
import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AllCategories = () => {
    const [category, setcategory] = useState([])
    useEffect(() => {
        const url = 'https://api.rebzigo.com/category';
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setcategory(data)
            })
    }, [])

    const handleDelete = (id) => {
        const proced = window.confirm("Are Your Sure Delete This User");
        if (proced) {
            const url = `https://api.rebzigo.com/category/${id}`;

            fetch(url, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {

                    const reamingData = category?.filter((user) => user._id !== id);
                    setcategory(reamingData);
                    toast.success("Succesfully Delete User");
                });
        }
    };
    const navigate = useNavigate()
    const handleUpdateProduct = (id) => {
        navigate(`/dashboard/updateCategory/${id}`);
    }
    return (
        <section className="my-4">
            <h1 className="text-2xl font-bold text-center mb-4">Categories Managment</h1>
            <div className="flex items-center w-10/12 mx-auto justify-between my-4">
                <div className="relative overflow-hidden w-8/12 p-0 border-[1px] border-primary rounded-full">
                    <input
                        type="text"
                        className="placeholder-secondary border-none px-6 pl-10 md:text-sm text-[8px] font-mono input py-4 w-full rounded-full text-secondary"
                        placeholder="Search by Category ..."
                    // value={searchFilter}
                    // onChange={handleSearchChange}
                    />
                    <h1 className=' absolute top-1/3 left-4 text-primary'><BsSearch /></h1>
                </div>
                <Link to="/dashboard/addCategory" className="bg-primary text-white tracking-wider border border-primary hover:bg-transparent hover:text-primary transition duration-300 px-4 py-2 cursor-pointer">Add New Category</Link>
            </div>
            <div className="w-11/12 mx-auto">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div className="max-w-full overflow-x-auto rounded-md">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-primary/40 text-center font-mono ">
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               border-l border-transparent
                               font-mono
                               "
                                        >
                                            Name
                                        </th>
                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               font-mono
                               "
                                        >
                                            Sub Categories
                                        </th>

                                        <th
                                            className="
                               w-1/6
                               min-w-[160px]
                               text-lg
                               font-semibold
                               text-secondary
                               py-4
                               lg:py-4
                               px-3
                               lg:px-4
                               "
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                {category.map((item) => (
                                    <tbody>
                                        <tr className="font-mono">
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-l border-primary
                               "
                                            >
                                                {item?.category}
                                            </td>
                                            <td
                                                className="
                               text-center text-dark
                               font-medium
                               text-secondary
                               py-5
                               px-2 bg-transparent
                               border-b border-primary
                               "
                                            >
                                                {item?.subCategory}
                                            </td>
                                            <td
                                                className="
                                              text-center text-dark
                                              font-medium
                                              text-secondary
                                              py-5
                                              px-2 bg-transparent
                                              border-b border-primary
                                              "
                                            >
                                                <div className=" cursor-pointer justify-center flex items-center gap-2">
                                                    <h1 onClick={() => handleDelete(item?._id)}
                                                        className="border-r border-primary px-2 text-red-500">Delete</h1>
                                                    <h1 onClick={() => handleUpdateProduct(item?._id)}>Edit</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllCategories;
