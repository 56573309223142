import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CompleteOrder = () => {
    const [ordersByDate, setOrdersByDate] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = () => {
        fetch(`https://api.rebzigo.com/orders`)
            .then(res => res.json())
            .then(data => {
                // Group orders by date
                const completeOrder = data.filter(order => order.status === 'Paid');
                const ordersGroupedByDate = {};
                completeOrder?.forEach(order => {
                    const date = formatDate(new Date(order?.date));
                    if (!ordersGroupedByDate[date]) {
                        ordersGroupedByDate[date] = [];
                    }
                    ordersGroupedByDate[date].push(order);
                });
                setOrdersByDate(ordersGroupedByDate);
            });
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleNavigateOrders = (orderId) => {
        navigate(`/dashboard/completeOrderDetails/${orderId}`);
    };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.rebzigo.com/orders/${id}`);
            // After deletion, refetch orders
            fetchOrders();
            toast.success("Order deleted successfully");
        } catch (error) {
            toast.error("Error deleting order:", error);
            toast.error("Error deleting order");
        }
    };
    return (
        <section className="my-4">
            <div className="flex justify-around items-center py-4">
                <h1 className="text-2xl font-bold text-center">Complete Orders Management</h1>
            </div>
            {Object.entries(ordersByDate).map(([date, orders]) => {
                const totalSellPrice = orders?.reduce((total, order) => total + (order.discountedTotal || 0), 0)
                const totalBenifitPriceData = orders?.reduce((total, order) => total + (order.purchaseTotal || 0), 0);
                const totalBenifits = parseFloat(totalSellPrice) - parseFloat(totalBenifitPriceData)
                return (
                    <div key={date} className="w-full">
                        <div className="flex items-center justify-between w-10/12 my-4">
                            <h2 className="text-xl font-bold">{date}'s Orders</h2>
                        </div>
                        <table className="table-auto w-full">
                            <thead>
                                <tr className="bg-primary/40 text-center font-mono">
                                    <th>Order ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders?.map(order => (
                                    <tr key={order._id} className="font-mono">
                                        <td>{order._id}</td>
                                        <td>
                                            <div className="justify-center flex items-center gap-2">
                                                <button onClick={() => handleNavigateOrders(order._id)}>Show</button>
                                                <button className="px-2 py-1 bg-primary text-white ml-3" onClick={() => handleDelete(order._id)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <h1 className="border-t-[1px] my-2 border-black"> </h1>
                        <h1>Total Sell - {totalSellPrice} taka</h1>
                        <h1>Total Benifit - {totalBenifits} taka</h1>
                    </div>
                )
            })}
        </section>
    );
};

export default CompleteOrder;
