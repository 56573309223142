import React, { useEffect, useState } from 'react';
import { FaBangladeshiTakaSign } from "react-icons/fa6";
import Modal from 'react-modal';
import { MdOutlineBookmarkBorder } from "react-icons/md";
import MontlyDataOrder from './MontlyDataOrder';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import PaidOrderCard from './PaidOrderCard';
const AdminDashboard = () => {
    const [orders, setOrders] = useState([])
    const [totalSell, setTotalSell] = useState([])
    const [totalBenifit, setTotalBenifit] = useState([])
    const [totalOrder, setTotalOrder] = useState([])

    const reverseTotalSell = [...totalSell].reverse()
    const reverseTotalBenifits = [...totalBenifit].reverse()
    const reverseTotalOrder = [...totalOrder].reverse()
    const [pendingOrder, setPendingOrder] = useState([])
    const [paidOrder, setPaidOrder] = useState([])
    const url = "https://api.rebzigo.com/orders";
    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setOrders(data)
                const totalSellData = data.filter(order => order.sell === 'totalSell');
                setTotalSell(totalSellData);
                const totalBenifitData = data.filter(order => order.benifit === 'totalBenifit');
                setTotalBenifit(totalBenifitData);
                const totalOrderData = data.filter(order => order.order === 'totalOrder');
                setTotalOrder(totalOrderData);
                const pendingOrder = data.filter(order => order.status === 'Pending');
                setPendingOrder(pendingOrder)
                const paidorder = data.filter(order => order.status === 'Paid');
                setPaidOrder(paidorder)
            })
    }, [orders, totalBenifit, totalSell, totalOrder])
    // Total Price By Total sell Total Benifit, Total order 
    const totalSellPrice = totalSell.reduce((total, order) => total + (order?.discountedTotal || 0), 0);
    // Total Benifit 
    const totalSellPriceData = totalBenifit.reduce((total, order) => total + (order.discountedTotal || 0), 0);
    const totalBenifitPriceData = totalBenifit.reduce((total, order) => total + (order.purchesTotal || 0), 0);
    const totalBenifits = parseFloat(totalSellPriceData) - parseFloat(totalBenifitPriceData)
    const [isSellModalOpen, setIsSellModalOpen] = useState(false);
    const openSellModal = () => setIsSellModalOpen(true);
    const closeSellModal = () => setIsSellModalOpen(false);

    const [isBenifitModalOpen, setIsBenifitModalOpen] = useState(false);
    const openBenifitModal = () => setIsBenifitModalOpen(true);
    const closeBenifitModal = () => setIsBenifitModalOpen(false);

    // State variables and functions for Today Order modal
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const openOrderModal = () => setIsOrderModalOpen(true);
    const closeOrderModal = () => setIsOrderModalOpen(false);

    const NewMontlySell = "Monthly Sell"

    const handleStatusChange = async (orderId, newMonthlySell) => {
        try {
            const response = await fetch(`https://api.rebzigo.com/orders/${orderId}/updateMontlySell`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ NewMontlySell: newMonthlySell }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message || 'Failed to update sell value');
            }
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order._id === orderId ? { ...order, sell: newMonthlySell } : order
                )
            );
            toast.success('Successfully Add Monthly Sell');
        } catch (error) {
            toast.error('Error updating sell value:', error.message);
        }
    };
    const NewMontlyBenifit = "Monthly Benifit"
    const handleUpdataMonthlyBenifit = async (orderId, newMonthlyBenifit) => {
        try {
            const response = await fetch(`https://api.rebzigo.com/orders/${orderId}/updateMontlyBenifit`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ NewMontlyBenifit: newMonthlyBenifit }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message || 'Failed to update benefit value');
            }
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order._id === orderId ? { ...order, benifit: newMonthlyBenifit } : order
                )
            );
            toast.success('Successfully Add Monthly Benifit');
        } catch (error) {
            toast.error('Error updating benefit value:', error.message);
        }
    };

    const NewMontlyOrder = "Monthly Order"
    const handleMonltyOrder = async (orderId, newMonthlyOrder) => {
        try {
            const response = await fetch(`https://api.rebzigo.com/orders/${orderId}/updateMontlyOrder`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ NewMontlyOrder: newMonthlyOrder }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message || 'Failed to update order value');
            }
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order._id === orderId ? { ...order, order: newMonthlyOrder } : order
                )
            );
            toast.success('Successfully Add Monthly Order');
        } catch (error) {
            toast.error('Error updating order value:', error.message);
        }
    };
    return (
        <div className='mt-20'>
            <div className=''>
                <div className='grid grid-cols-4'>
                    <div className='grid grid-cols-1 md:grid-cols-3 w-11/12 mx-auto col-span-3'>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Today Sell</h1>
                            <h1 className='flex items-center gap-1 justify-center'><FaBangladeshiTakaSign />{totalSellPrice}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openSellModal}>Add</button>                            </div>
                        </div>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Today Benifit</h1>
                            <h1 className='flex items-center gap-1 justify-center'><FaBangladeshiTakaSign />{totalBenifits}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openBenifitModal}>Add</button>                            </div>
                        </div>
                        <div className='bg-white w-11/12 text-center py-5'>
                            <h1 className='text-lg'>Today Order</h1>
                            <h1 className='flex items-center gap-1 justify-center'><MdOutlineBookmarkBorder />{totalOrder.length}</h1>
                            <div>
                                <button className='px-5 rounded-sm tracking-wider py-1 mt-2 bg-black text-white' onClick={openOrderModal}>Add</button>                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-11/12 text-center py-5">
                        <h1 className="">Pending Orders</h1>
                        <p className='py-2'>{pendingOrder.length}</p>
                        <Link to='/dashboard/orders' className='px-5 rounded-sm tracking-wider py-2 bg-black text-white'>Show</Link>
                    </div>
                </div>
                <MontlyDataOrder />
                <PaidOrderCard paidOrder={paidOrder} />
            </div>
            {/* Total Sell Modal  */}
            <Modal
                isOpen={isSellModalOpen}
                onRequestClose={closeSellModal}
                contentLabel="Today Sell Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeSellModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseTotalSell?.map(sell => <div className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'>Total Price - <span className='text-sm'><FaBangladeshiTakaSign /></span> {sell?.discountedTotal}</h1>
                                    <button onClick={() => handleStatusChange(sell?._id, NewMontlySell)} className='px-6 py-1 bg-black text-white'>Add</button>
                                </div>
                                <hr />
                                <div>
                                    {
                                        sell?.orderedProducts?.map((item, index) => <div className='flex items-center gap-10 font-semibold w-10/12 justify-between mt-2'>

                                            <h1>{index + 1} {item?.name}</h1>
                                            <h1 className='flex items-center'><FaBangladeshiTakaSign />{(parseFloat(item?.sellPrice) * parseFloat(item?.quantity))}</h1>
                                            <h1>{item?.quantity} <span>item</span></h1>
                                        </div>)
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
            {/* Total Benifits Modal  */}
            <Modal
                isOpen={isBenifitModalOpen}
                onRequestClose={closeBenifitModal}
                contentLabel="Today Benifit Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeBenifitModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseTotalBenifits?.map(sell => <div className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Benifits - </span> <span className='text-sm'><FaBangladeshiTakaSign /></span> {(parseFloat(sell?.discountedTotal) - parseFloat(sell?.purchesTotal)).toFixed(0, 2)}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Sell Price - </span> <span className='text-sm'><FaBangladeshiTakaSign /></span>{sell?.discountedTotal} </h1>
                                    <button onClick={() => handleUpdataMonthlyBenifit(sell._id, NewMontlyBenifit)} className='px-6 py-1 bg-black text-white'>Add</button>
                                </div>
                                <hr />
                                <div>
                                    {
                                        sell?.orderedProducts?.map((item, index) => <div className='flex items-center gap-10 font-semibold w-10/12 justify-between mt-2'>

                                            <h1>{index + 1} {item?.name}</h1>
                                            <h1 className='flex items-center'><FaBangladeshiTakaSign />{(parseFloat(item?.sellPrice) * parseFloat(item?.quantity))}</h1>
                                            <h1>{item?.quantity} <span>item</span></h1>
                                        </div>)
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
            {/* Total Order Modal  */}
            <Modal
                isOpen={isOrderModalOpen}
                onRequestClose={closeOrderModal}
                contentLabel="Today Order Modal"
                style={{
                    overlay: {
                        zIndex: 10,
                    },
                    content: {
                        zIndex: 11,
                    },
                }}
            >
                <div>
                    <div className='flex justify-end'>
                        <button onClick={closeOrderModal} className='text-2xl font-bold text-red-500'>X</button>
                    </div>
                    <div className='flex flex-col gap-4 text-md capitalize'>
                        {
                            reverseTotalOrder?.map(sell => <div className='bg-[#D9DADB] md:px-10 md:py-4 rounded-md'>
                                <div className='flex flex-wrap justify-between items-center py-2'>
                                    <h1 className='font-semibold text-md tracking-wider'>Date - {sell?.date}</h1>
                                    <h1 className='text-lg font-semibold tracking-wider flex items-center'> <span className='text-sm'>Total Product - </span> {sell?.orderedProducts?.length} </h1>
                                    <button onClick={() => handleMonltyOrder(sell?._id, NewMontlyOrder)} className='px-6 py-1 bg-black text-white'>Add</button>
                                </div>
                                <hr />
                                <div>
                                    {
                                        sell?.orderedProducts?.map((item, index) => <div className='flex items-center gap-10 font-semibold w-10/12 justify-between mt-2'>

                                            <h1>{index + 1} {item?.name}</h1>
                                            <h1 className='flex items-center'><FaBangladeshiTakaSign />{(parseFloat(item?.sellPrice) * parseFloat(item?.quantity))}</h1>
                                            <h1>{item?.quantity} <span>item</span></h1>
                                        </div>)
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AdminDashboard;