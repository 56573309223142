import React from 'react';

const IntroductoryPage = () => {
  return (
    <div>
      <div class="flex items-center justify-center w-full  bg-gray-300 rounded h-screen">
        <h1 className='md:text-6xl text-3xl font-bold tracking-widest'>Welcome To Rebzigo</h1>
      </div>
    </div>
  );
};

export default IntroductoryPage;