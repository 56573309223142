import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink, Outlet } from "react-router-dom";
import auth from "../../../firebase.init";
import { RiUserReceivedLine } from 'react-icons/ri';
import { AiOutlineShopping } from 'react-icons/ai';
import { MdOutlinePostAdd } from 'react-icons/md';
import { BsBookmarks } from 'react-icons/bs';
import Navbar from "../../../layout/main/Navbar";
import UseAdmin from "../../../hooks/UseAdmin";
import UseEmployee from "../../../hooks/UseEmployee";
const Slider = () => {
    const [user] = useAuthState(auth);
    const [admin] = UseAdmin(user);
    const [employee] = UseEmployee(user)
    return (
        <div>
            <Navbar />
            <div className="">
                <label
                    tabIndex="1"
                    for="dashboard-sidebar"
                    class="btn btn-ghost lg:hidden "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h7"
                        />
                    </svg>
                </label>
            </div>
            <div class="drawer drawer-mobile ">
                <input id="dashboard-sidebar" type="checkbox" class="drawer-toggle" />
                <div class="drawer-content flex flex-col h-auto">
                    <Outlet></Outlet>
                </div>
                <div class="drawer-side mt-20 md:pl-10 w-60  mx-4">
                    <label for="dashboard-sidebar" class="drawer-overlay"></label>
                    <ul class="menu p-4 overflow-y-auto bg-white w-80">

                        {
                            admin ? <ul>
                                <li className="text-secondary text-md mb-4 w-40">
                                    <NavLink to="/dashboard"> <BsBookmarks className="text-xl" /> DashBord</NavLink>
                                </li>
                                <li className="text-secondary text-md mb-4 w-40">
                                    <NavLink to="/dashboard/AllUsers"> <RiUserReceivedLine className="text-xl" />All Users</NavLink>
                                </li>
                                <li className="text-secondary text-md mb-4 w-48">
                                    <NavLink to="/dashboard/AllProducts"> <AiOutlineShopping className="text-xl" /> All Products</NavLink>
                                </li>
                                <li className="text-secondary text-md mb-4 w-48">
                                    <NavLink to="/dashboard/AllCategories"> <AiOutlineShopping className="text-xl" />All Categories</NavLink>
                                </li>
                                <li className="text-secondary text-md mb-4 w-48">
                                    <NavLink to="/dashboard/couponCode"> <RiUserReceivedLine className="text-xl" />Coupon Code</NavLink>
                                </li>
                                <li className="text-secondary text-md mb-4 w-48">
                                    <NavLink to="/dashboard/requestProduct"> <RiUserReceivedLine className="text-xl" />Request Products</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/blogs"><MdOutlinePostAdd className="text-xl" /> Blogs Post</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/employee"><MdOutlinePostAdd className="text-xl" /> Employee</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/addNews"><MdOutlinePostAdd className="text-xl" /> Add News</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/showBanner"><MdOutlinePostAdd className="text-xl" /> Add Banner</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/showNumber"><MdOutlinePostAdd className="text-xl" />Add Number</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/showDiscountImg"><MdOutlinePostAdd className="text-xl" />Discount Img</NavLink>
                                </li>
                                <li className="text-secondary text-md w-44  mb-4">
                                    <NavLink to="/dashboard/allSize"><MdOutlinePostAdd className="text-xl" />All Size</NavLink>
                                </li>

                            </ul> : employee && <ul> <li className="text-secondary text-md mb-4 w-40">
                                <NavLink to="/dashboard"> <BsBookmarks className="text-xl" /> DashBord</NavLink>
                            </li></ul>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Slider;
