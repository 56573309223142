import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddSize = () => {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://api.rebzigo.com/size', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    size: data.size
                }),
            });
            if (response.ok) {
                toast.success('Size Create successfully');
                navigate('/dashboard/AddProduct')

            } else {
                toast.error('Failed to Create Size');
            }
        } catch (error) {
            toast.error('Error adding Create Size:', error);
        }
    };
    return (
        <div>
            <div className='mt-10'>
                <form onSubmit={handleSubmit(onSubmit)} className='w-10/12 mx-auto mt-4'>
                    <div>
                        <label htmlFor='name' className="mt-3 font-mono text-secondary">Create New Size</label>
                        <input
                            id='size'
                            type="text"
                            placeholder="Enter Size name"
                            className="block px-4 py-2 mt-2 bg-transparent w-full input font-mono text-md border-2 border-primary placeholder-secondary/70 text-secondary "
                            {...register("size")}
                            required
                        />
                    </div>

                    <input type="submit" value="Submit" className='text-md bg-primary text-white border border-primary hover:bg-transparent hover:text-primary rounded-md transition duration-300 w-full py-4 mt-4 cursor-pointer' />
                </form>
            </div>

        </div>
    );
};

export default AddSize;