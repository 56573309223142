import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
const Discount = () => {
    const [news, setNews] = useState([])
    useEffect(() => {
        fetch('https://api.rebzigo.com/news')
            .then(res => res.json())
            .then(data => setNews(data))
    }, [])

    const reverse = [...news].reverse()
    return (
        <div className='md:my-10 py-4 bg-white'>
            <Marquee>
                {
                    reverse?.slice(0, 1).map(news => <>
                        <h1 className='text-blue-900 text-xl'>{news?.news ? news.news : 'No Discount Available'}</h1>
                    </>)
                }
            </Marquee>
        </div>
    );
};

export default Discount;